import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
} from '@material-ui/core';
import Playback from '../../../services/Playback';


const PREVIEW_DURATION = 5;

function ListenPiece({
  onStepDone,
}) {
  useEffect(() => {
    Playback.addInitCallback(() => {
      const {
        getCurrentTime,
        isPlaying,
        lockScrubbing,
        playPause,
        stop,
      } = window.MUSICEYES;
      lockScrubbing(true);

      if (!isPlaying()) {
        playPause();
      }

      const interval = setInterval(() => {
        if (Math.floor(getCurrentTime()) >= PREVIEW_DURATION && isPlaying()) {
          stop();
          clearInterval(interval);
          onStepDone();
        }
      }, 1000);
    });

    return () => {
      if (window.MUSICEYES) {
        window.MUSICEYES.lockScrubbing(false);
      }
    };
  }, [onStepDone]);

  return (
    <Typography>
      Listen to the first 30 seconds and imagine what kind of visuals.
    </Typography>
  );
}

ListenPiece.propTypes = {
  onStepDone: PropTypes.func.isRequired,
};

export default ListenPiece;

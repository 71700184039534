import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  RadioGroup,
} from '@material-ui/core';
import SwatchesPicker from './SwatchesPicker';
import Swatch from './Swatch';


function ColorPicker({
  editable,
  colors,
  value,
  onSelectionChange,
  onColorChange,
  columns,
  disabled,
}) {
  const columnWidth = Math.floor(12 / columns);

  function handleSelectionChange(newColor, index) {
    const colorIndex = index || colors.findIndex((color) => color === newColor);
    onSelectionChange(colorIndex);
  }

  return (
    <Grid container>
      <Grid item xs={editable ? 6 : 12}>
        <RadioGroup
          value={colors[value]}
          onChange={({ target }) => handleSelectionChange(target.value)}
        >
          <Grid container>
            {colors.map((color) => (
              <Grid
                item
                xs={columnWidth}
                key={color}
              >
                <Swatch
                  key={color}
                  value={color}
                  props={{ color }}
                  disabled={disabled}
                  disableRipple
                />
              </Grid>
            ))}
          </Grid>
        </RadioGroup>
      </Grid>
      {editable && (
        <Grid item xs={6}>
          <SwatchesPicker
            color={colors[value]}
            onChangeComplete={({ hex }) => onColorChange(hex, value)}
            width="100%"
          />
        </Grid>
      )}
    </Grid>
  );
}

ColorPicker.propTypes = {
  colors: PropTypes.arrayOf(
    PropTypes.string,
  ).isRequired,
  value: PropTypes.number,
  onColorChange: PropTypes.func,
  onSelectionChange: PropTypes.func,
  columns: PropTypes.number,
  editable: PropTypes.bool,
  disabled: PropTypes.bool,
};

ColorPicker.defaultProps = {
  editable: false,
  columns: 4,
  onColorChange: () => {},
  onSelectionChange: () => {},
  value: null,
  disabled: false,
};

export default ColorPicker;

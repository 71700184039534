const backgroundColorStyles = [
  { key: 'no-gradient', label: 'Flat' },
  { key: 'vertical-soft', label: 'Vertical (soft)' },
  { key: 'vertical-strong', label: 'Vertical (strong)' },
  { key: 'vertical-short', label: 'Vertical (short)' },
  { key: 'horizontal-soft', label: 'Horizontal (soft)' },
  { key: 'horizontal-strong', label: 'Horizontal (strong)' },
  { key: 'radial', label: 'Radial' },
  { key: 'radial-dark', label: 'Radial (dark)' },
  { key: 'radial-inversed', label: 'Radial (inversed)' },
];

export default backgroundColorStyles;

import React, {
  Component,
  createRef,
} from 'react';
import PropTypes from 'prop-types';
import {
  Box,
} from '@material-ui/core';
import jQuery from 'jquery';
import debounce from 'lodash.debounce';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import timelineSelectors from '../store/selectors/timeline';
import triggersSelectors from '../store/selectors/triggers';
import { animationsSelectors } from '../store/selectors/animations';
import { cameraSelectors } from '../store/selectors/camera';
import { tabsSelectors } from '../store/selectors/tabs';
import TimelineLegend from '../components/Timeline/TimelineLegend';
import TimelineRows from '../components/Timeline/TimelineRows';
import { clamp } from '../common/Utils';
import {
  selectTrigger,
  selectMoment,
  selectCamera,
  selectAnimation,
  createTrigger,
  createAnimation,
  createMoment,
  createCamera,
  updateTimelineItem,
} from '../store/actions/timeline';
import { MusaicDataContext } from '../contexts/MusaicDataContext';
import {
  triggerSections,
  timelineTriggersOrder,
} from '../common/TriggerSections';
import {
  changeTriggerItem,
} from '../store/actions/triggers';
import raf from 'raf';

const styles = {
  wrapper: {
    position: 'relative',
    height: '100%',
    overflowY: 'auto',
    overflowX: 'hidden',
    backgroundColor: '#F2F2F2',
  },
};

const VISIBLE_ROWS = 5;
const TIMELINE_ROW_HEIGHT = 49;

function parseLegendItems(timelineItems) {
  return Object.entries(timelineItems)
    .map(([key, { name, items }]) => ({
      key,
      name,
      items,
    }));
}


function getVisibleLayers(visibleItems, layer) {
  if (visibleItems.includes(layer)) {
    const index = visibleItems.findIndex((layerIndex) => layerIndex === layer);
    return [
      ...visibleItems.slice(0, index),
      ...visibleItems.slice(index + 1, visibleItems.length),
    ];
  }

  return [
    ...visibleItems,
    layer,
  ];
}

function changeTriggersList(visibleTriggers) {
  const {
    updateIgnoreTriggersList,
  } = window.MUSICEYES;

  updateIgnoreTriggersList([
    'CAMANGL',
    'CAMROLL',
    ...visibleTriggers
      .map((index) => timelineTriggersOrder[index])
      .map((trigger) => triggerSections[trigger])
      .flatten(),
  ]);
}

class Timeline extends Component {
  constructor(props) {
    super(props);

    this.state = {
      manualyChangedAnimations: [],
      visibleAnimations: [],
      visibleTriggers: [0, 1, 2, 3, 4],
    };

    this.interval = null;
    this.scrollerRef = createRef();
    this.wrapperRef = createRef();

    this.getTimelineItems = this.getTimelineItems.bind(this);
    this.getTimelineLegendItems = this.getTimelineLegendItems.bind(this);
    this.handleAddItem = this.handleAddItem.bind(this);
    this.handleItemSelect = this.handleItemSelect.bind(this);
    this.handleOnResizeStop = this.handleOnResizeStop.bind(this);
    this.handleOnRowHover = this.handleOnRowHover.bind(this);
    this.handleTimelineItemUpdate = this.handleTimelineItemUpdate.bind(this);
    this.handleVisibilityChange = this.handleVisibilityChange.bind(this);
    this.isSelected = this.isSelected.bind(this);
    this.restoreVisibleAnimations = this.restoreVisibleAnimations.bind(this);
    this.handleOnWheel = this.handleOnWheel.bind(this);
    this.handleOnDragStop = this.handleOnDragStop.bind(this);
    this.handleScrollEnd = debounce(this.handleScrollEnd.bind(this), 250);
    this.setRulerPosition = this.setRulerPosition.bind(this);

    Object.defineProperties(this, {
      pixelsPerSecond: { get: () => this.getPixelsPerSecond() },
    });
  }

  componentDidUpdate(prevProps) {
    const {
      isScriptLoaded,
    } = this.props;

    window.addEventListener('keydown', (event) => {
      if (event.keyCode === 32 && event.target.tagName === 'BODY') {
        event.preventDefault();
      }
    });

    if (isScriptLoaded !== prevProps.isScriptLoaded) {
      if (window.MUSICEYES) {
        const {
          Events: {
            INIT_COMPLETE,
          },
          EventBus,
        } = window.MUSICEYES;

        EventBus.on(INIT_COMPLETE, () => {
          const {
            tracks,
          } = this.context;

          const indicesArray = new Array(tracks.length)
            .fill(null)
            .map((_, index) => index);

          this.setState({
            visibleAnimations: indicesArray,
            manualyChangedAnimations: indicesArray,
          });
        });

        this.listenPlayerTime();
      }
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  getTimelineItems() {
    const {
      activeTab,
      triggersList,
      momentsList,
      animationsList,
      cameraList,
    } = this.props;

    if (activeTab === 'triggers') {
      return triggersList;
    }
    if (activeTab === 'moments') {
      return momentsList;
    }
    if (activeTab === 'camera') {
      return cameraList;
    }
    return animationsList;
  }

  getTimelineLegendItems() {
    const {
      activeTab,
    } = this.props;
    const timelineItems = this.getTimelineItems();

    const {
      tracks: contextTracks,
    } = this.context;

    if (activeTab === 'animations') {
      return contextTracks;
    }

    return parseLegendItems(timelineItems);
  }

  getSelectedItem() {
    const {
      activeTab,
      selectedTriggerId,
      selectedMoment,
      selectedCamera,
      selectedAnimation,
    } = this.props;

    switch (activeTab) {
      case 'triggers':
        return selectedTriggerId;
      case 'moments':
        return selectedMoment;
      case 'camera':
        return selectedCamera;
      default:
        return selectedAnimation;
    }
  }

  getMusaicDuration() {
    const {
      musaic,
    } = this.context;

    return musaic
      ? ((Math.floor(musaic.duration / 60) * 60) + ((musaic.duration / 60) % 60)) * 1000
      : 0;
  }

  getPlayerWidth() {
    try {
      const { overflow } = this.wrapperRef.current.style;
      this.wrapperRef.current.style.overflow = 'hidden';
      const width = this.scrollerRef.current.offsetWidth;
      this.wrapperRef.current.style.overflow = overflow;
      return width;
    } catch (err) { }
  }

  getPixelsPerSecond() {
    try {
      const playerPixelWidth = this.getPlayerWidth();
      const { width } = window.MUSICEYES.getVisibleRange();
      const pixelsPerSecond = (playerPixelWidth / width);
      return pixelsPerSecond;
    } catch (err) {
      return 50
    }
  }

  setRulerPosition() {
    // get the current PIXELS_PER_SECOND value
    try {
      const MUSICEYES = window.MUSICEYES;
      const { currentNoteSpacing } = window.MUSICEYES.stage().rendererThree.context;
      // Get the current time in seconds.
      const current = window.MUSICEYES.getCurrentTime();
      let { left, right, width } = window.MUSICEYES.getVisibleRange();

      const playerPixelWidth = this.getPlayerWidth();
      const playerPixelMidpoint = playerPixelWidth / 2;

      const pixelsPerSecond = (playerPixelMidpoint / (right - current));
      const paddingLeft = clamp(playerPixelMidpoint - (current * pixelsPerSecond), 0, playerPixelMidpoint);

      // const test = window.MUSICEYES.stage().rendererThree.playHeadTime * window.MUSICEYES.stage().rendererThree.context.currentNoteSpacing;
      left *= currentNoteSpacing;
      jQuery(this.scrollerRef.current).css('padding-left', paddingLeft);
      window.testing = this.scrollerRef.current;
      this.scrollerRef.current.scrollTo({
        left,
        top: 0,
        behaviour: 'smooth',
      });
      // this.setState({ pixelsPerSecond });
    } catch (err) { };
    raf(this.setRulerPosition);
  }

  listenPlayerTime() {
    try {
      // entire ruler length
      const duration = this.getMusaicDuration();
    } catch (err) { }

    raf(this.setRulerPosition)
  }

  handleItemSelect(itemId, key) {
    const {
      activeTab,
      selectAnimation,
      selectTrigger,
      selectMoment,
      selectCamera,
    } = this.props;

    const selectedItem = this.getSelectedItem();

    if (selectedItem === itemId) {
      return;
    }

    if (activeTab === 'triggers') {
      selectTrigger({
        id: itemId,
        key,
      });
    } else if (activeTab === 'moments') {
      selectMoment(itemId);
    } else if (activeTab === 'camera') {
      selectCamera(itemId);
    } else {
      selectAnimation(itemId, key);
    }
  }

  handleTimelineItemUpdate(key, item) {
    const {
      activeTab,
      updateTimelineItem,
    } = this.props;

    const selectedItem = this.getSelectedItem();

    updateTimelineItem({
      key,
      activeTab,
      selectedItem,
      item,
    });
  }

  isSelected(id) {
    const {
      activeTab,
      selectedAnimation,
      selectedMoment,
      selectedCamera,
      selectedTriggerId,
    } = this.props;

    switch (activeTab) {
      case 'triggers':
        return selectedTriggerId === id;
      case 'animations':
        return selectedAnimation === id;
      case 'moments':
        return selectedMoment === id;
      case 'camera':
        return selectedCamera === id;
      default:
        return selectedAnimation === id;
    }
  }

  handleOnResizeStop(key, ref, position) {
    this.handleTimelineItemUpdate(key, {
      transitionDuration: ref.offsetWidth / 10,
      startTime: position.x,
    });
  }

  handleAddItem(key, payload) {
    const {
      activeTab,
      createTrigger,
      createAnimation,
      createMoment,
      createCamera,
    } = this.props;

    switch (activeTab) {
      case 'animation':
        return createAnimation(key, payload);
      case 'triggers':
        return createTrigger(key, payload);
      case 'moments':
        return createMoment(key, payload);
      case 'camera':
        return createCamera(key, payload);
      default:
        return createAnimation(key, payload);
    }
  }

  handleVisibilityChange(layer) {
    const {
      activeTab,
    } = this.props;

    const {
      visibleTriggers,
      visibleAnimations,
    } = this.state;

    if (activeTab === 'triggers') {
      const visibleLayers = getVisibleLayers(visibleTriggers, layer);

      changeTriggersList(visibleLayers);

      this.setState({
        visibleTriggers: visibleLayers,
      });
    } else if (activeTab === 'moments') {
      // console.log(activeTab, layer);
    } else if (activeTab === 'camera') {
      // console.log(activeTab, layer);
    } else {
      const visibleLayers = getVisibleLayers(visibleAnimations, layer);

      this.setState({
        visibleAnimations: visibleLayers,
        manualyChangedAnimations: visibleLayers,
      });

      window.MUSICEYES.layerSolo(visibleLayers);
    }
  }


  handleOnRowHover(id) {
    const {
      activeTab,
    } = this.props;

    if (activeTab === 'animations' || activeTab === 'color-palette') {
      window.MUSICEYES.layerSolo([id]);

      this.setState({
        visibleAnimations: [id],
      });
    }
  }

  handleOnWheel() {
    const time = this.scrollerRef.current.scrollLeft / 100;

    window.MUSICEYES.pause();
    window.MUSICEYES.gotoTime(time);

    clearInterval(this.interval)

    this.handleScrollEnd();
  }

  handleOnDragStop(key, direction, id) {
    const {
      activeTab,
      selectedTriggerId,
      changeTriggerItem,
    } = this.props;

    if (activeTab === 'triggers') {
      changeTriggerItem({
        section: key,
        selectedTrigger: selectedTriggerId,
        data: {
          startTime: direction.x,
        },
      });
    }
  }

  handleScrollEnd() {
    // this.listenPlayerTime();
  }

  restoreVisibleAnimations() {
    const {
      activeTab,
    } = this.props;

    if (activeTab !== 'triggers' && activeTab !== 'moments') {
      const {
        manualyChangedAnimations,
      } = this.state;

      window.MUSICEYES.layerSolo(manualyChangedAnimations);

      this.setState({
        visibleAnimations: manualyChangedAnimations,
      });
    }
  }

  render() {
    const {
      visibleAnimations,
      visibleTriggers,
      pixelsPerSecond
    } = this.state;

    const {
      activeTab,
      classes,
    } = this.props;

    const duration = this.getMusaicDuration();



    if (activeTab === 'phrasing') {
      return null;
    }

    const isPillPrimary = (activeTab !== 'color-palette' && activeTab !== 'animations')
      ? 'primary'
      : 'secondary';
    const timelineLegendItems = this.getTimelineLegendItems();
    const timelineItems = this.getTimelineItems();
    const rulerHeight = 30;
    const maxHeight = timelineLegendItems.length > VISIBLE_ROWS
      ? (VISIBLE_ROWS * TIMELINE_ROW_HEIGHT) + rulerHeight
      : timelineLegendItems.length * TIMELINE_ROW_HEIGHT + rulerHeight;

    return timelineItems && (
      <Box className={classes.wrapper} display="flex" style={{ maxHeight }} ref={this.wrapperRef}>
        <TimelineLegend
          items={timelineLegendItems}
          isSelected={this.isSelected}
          visibleIndices={activeTab === 'triggers' ? visibleTriggers : visibleAnimations}
          onVisibilityChange={this.handleVisibilityChange}
          hasVisibilityIcon={activeTab !== 'camera'}
        />
        <TimelineRows
          addItem={this.handleAddItem}
          enableResizing={activeTab !== 'triggers'}
          disableDragging={activeTab !== 'triggers'}
          handleItemSelect={this.handleItemSelect}
          handleOnResizeStop={this.handleOnResizeStop}
          handleOnRowHover={this.handleOnRowHover}
          handleMouseLeave={this.restoreVisibleAnimations}
          handleOnWheel={this.handleOnWheel}
          handleOnDragStop={this.handleOnDragStop}
          isPillPrimary={isPillPrimary}
          isSelected={this.isSelected}
          scrollerRef={this.scrollerRef}
          timelineItems={timelineItems}
          duration={duration}
          pixelsPerSecond={pixelsPerSecond}
        />
      </Box>
    );
  }
}

Timeline.contextType = MusaicDataContext;

Timeline.propTypes = {
  classes: PropTypes.shape({
    timeline: PropTypes.string,
    wrapper: PropTypes.string,
    scroller: PropTypes.string,
    scrollerInner: PropTypes.string,
    note: PropTypes.string,
  }).isRequired,
  isScriptLoaded: PropTypes.bool,
  activeTab: PropTypes.string.isRequired,
  selectTrigger: PropTypes.func.isRequired,
  selectAnimation: PropTypes.func.isRequired,
  updateTimelineItem: PropTypes.func.isRequired,
  selectedTriggerId: PropTypes.number,
  selectedAnimation: PropTypes.number,
  selectedMoment: PropTypes.number,
  selectedCamera: PropTypes.number,
  createTrigger: PropTypes.func.isRequired,
  createAnimation: PropTypes.func.isRequired,
  changeTriggerItem: PropTypes.func.isRequired,
  triggersList: PropTypes.shape({}).isRequired,
  momentsList: PropTypes.shape({}).isRequired,
  animationsList: PropTypes.shape({}).isRequired,
  cameraList: PropTypes.shape({}).isRequired,
};

Timeline.defaultProps = {
  isScriptLoaded: false,
  selectedTriggerId: null,
  selectedAnimation: null,
  selectedCamera: null,
  selectedMoment: null,
};

const mapStateToProps = (state) => ({
  selectedTriggerId: timelineSelectors.getSelectedTriggerId(state),
  selectedAnimation: timelineSelectors.getSelectedAnimation(state),
  selectedMoment: timelineSelectors.getSelectedMoment(state),
  selectedCamera: timelineSelectors.getSelectedCamera(state),
  triggersList: triggersSelectors.getTriggers(state),
  momentsList: triggersSelectors.getMoments(state),
  animationsList: animationsSelectors.getAnimations(state),
  cameraList: cameraSelectors.getCameras(state),
  activeTab: tabsSelectors.getActiveTab(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  selectTrigger,
  selectAnimation,
  selectMoment,
  selectCamera,
  createTrigger,
  createAnimation,
  createMoment,
  createCamera,
  updateTimelineItem,
  changeTriggerItem,
}, dispatch);


export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Timeline));

import {
  SAVE_REVISION,
} from '../actions/revisions';

export const initialState = {
  revisions: [],
};

export default (state = initialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case SAVE_REVISION:
      return {
        ...state,
        revisions: [
          ...state.revisions,
          payload,
        ],
      };
    default:
      return state;
  }
};

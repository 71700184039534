import omit from 'lodash.omit';
import pick from 'lodash.pick';
import {
  CHANGE_TRIGGERS_PANEL,
  CHANGE_TRIGGER_ITEM,
  TOGGLE_ACTIVE_TRIGGERS,
  UPDATE_TRIGGERS,
  REMOVE_TRIGGER,
} from '../actions/triggers';
import {
  ADD_TRIGGER,
  SELECT_TRIGGER,
  REMOVE_ACTIVE_TRIGGER,
} from '../actions/timeline';

const defaultTrigger = {
  background: {
    enabledTriggers: [],
    startTime: 0,
    transitionDuration: 5,
    backgroundColor: {
      startTime: 0,
      color: 0,
      style: 'no-gradient',
      transitionDuration: 5,
    },
    backgroundFlash: {
      startTime: 0,
      color: 0,
      intensity: '5',
      transitionDuration: 5,
    },
    backgroundParticle: {
      startTime: 0,
      particles: 'None',
      transitionDuration: 5,
    },
    backgroundShimmer: {
      startTime: 0,
      shimmer: 'default',
      transitionDuration: 5,
    },
  },
  motion: {
    enabledTriggers: [],
    startTime: 0,
    transitionDuration: 5,
    scrollSpeed: {
      startTime: 0,
      speed: 50,
      transitionDuration: 5,
    },
    layerSpeed: {
      startTime: 0,
      layer: 0,
      speed: 0,
      transitionDuration: 5,
    },
    magneticEffect: {
      startTime: 0,
      intensity: 50,
      transitionDuration: 5,
    },
  },
  notes: {
    enabledTriggers: [],
    startTime: 0,
    transitionDuration: 5,
    noteSpacing: {
      startTime: 0,
      spacing: 5,
      transitionDuration: 5,
    },
    transpose: {
      startTime: 0,
      interval: 0,
      layer: 0,
      transitionDuration: 5,
    },
  },
  light: {
    enabledTriggers: [],
    startTime: 0,
    transitionDuration: 5,
    noteGlow: {
      startTime: 0,
      intensity: 0,
      layer: 0,
      transitionDuration: 5,
    },
    noteShafts: {
      startTime: 0,
      intensity: 0,
      layer: 0,
      transitionDuration: 5,
    },
    noteAura: {
      startTime: 0,
      intensity: 0,
      layer: 0,
      transitionDuration: 5,
    },
    godrays: {
      startTime: 0,
      intensity: 0,
      layer: 0,
      transitionDuration: 5,
    },
  },
  other: {
    enabledTriggers: [],
    startTime: 0,
    transitionDuration: 5,
    hideThePast: {
      startTime: 0,
      hide: false,
      transitionDuration: 5,
    },
    musicStaff: {
      startTime: 0,
      type: 'none',
      transitionDuration: 5,
    },
  },
};

const initialState = {
  background: {
    name: 'Background',
    items: [],
  },
  motion: {
    name: 'Motion',
    items: [],
  },
  notes: {
    name: 'Notes',
    items: [],
  },
  light: {
    name: 'Light',
    items: [],
  },
  other: {
    name: 'Other',
    items: [],
  },
};

function toggleTriggers(triggerList, trigger) {
  if (triggerList.includes(trigger)) {
    return triggerList.filter((activePanel) => activePanel !== trigger);
  }

  return [
    ...triggerList,
    trigger,
  ];
}

const triggersReducer = (state = initialState, action) => {
  const { payload } = action;

  switch (action.type) {
    case CHANGE_TRIGGERS_PANEL: {
      const {
        selectedTrigger,
        data,
        triggerName,
        section,
      } = payload;

      const triggerIndex = state[section].items.findIndex((item) => item.id === selectedTrigger);

      const items = [
        ...state[section].items.slice(0, triggerIndex),
        {
          ...state[section].items[triggerIndex],
          [triggerName]: {
            ...state[section].items[triggerIndex][triggerName],
            ...data,
          },
        },
        ...state[section].items.slice(triggerIndex + 1, state.length),
      ];

      const enabledTriggers = pick(
        items[triggerIndex],
        items[triggerIndex].enabledTriggers,
      );

      const largestTransitionDuration = Object.values(enabledTriggers)
        .reduce((largestDuration, { transitionDuration }) => {
          if (transitionDuration > largestDuration) {
            return transitionDuration;
          }
          return largestDuration;
        }, 0);

      return {
        ...state,
        [section]: {
          ...state[section],
          items: [
            ...items.slice(0, triggerIndex),
            {
              ...items[triggerIndex],
              transitionDuration: largestTransitionDuration,
            },
            ...items.slice(triggerIndex + 1, state.length),
          ],
        },
      };
    }
    case TOGGLE_ACTIVE_TRIGGERS: {
      const {
        id,
        key,
        panel,
      } = payload;
      const triggers = state[key].items;
      const activeTriggerIndex = triggers.findIndex((trigger) => trigger.id === id);
      const activeTrigger = triggers[activeTriggerIndex];
      const { enabledTriggers } = activeTrigger;

      return {
        ...state,
        [key]: {
          ...state[key],
          items: [
            ...state[key].items.slice(0, activeTriggerIndex),
            {
              ...activeTrigger,
              enabledTriggers: toggleTriggers(enabledTriggers, panel),
            },
            ...state[key].items.slice(activeTriggerIndex + 1, state[key].items.length),
          ],
        },
      };
    }
    case ADD_TRIGGER: {
      const {
        key,
        data,
      } = payload;

      return {
        ...state,
        [key]: {
          ...state[key],
          items: [
            ...state[key].items,
            {
              ...defaultTrigger[key],
              ...data,
            },
          ],
        },
      };
    }
    case UPDATE_TRIGGERS:
      return {
        ...state,
        ...payload,
      };
    case REMOVE_ACTIVE_TRIGGER: {
      return omit(state, payload);
    }
    case SELECT_TRIGGER: {
      return Object.entries(state).reduce((acc, [key, section]) => ({
        ...acc,
        [key]: {
          ...section,
          items: section.items
            .filter(({ id, enabledTriggers }) => id === payload.id || enabledTriggers.length > 0),
        },
      }), {});
    }
    case REMOVE_TRIGGER: {
      const [section] = Object.entries(state)
        .find(([key, { items }]) => items.find(({ id }) => id === payload));

      return {
        ...state,
        [section]: {
          ...state[section],
          items: [
            ...state[section].items.filter(({ id }) => id !== payload),
          ],
        },
      };
    }
    case CHANGE_TRIGGER_ITEM: {
      const {
        data,
        section,
        selectedTrigger,
      } = payload;

      const triggerIndex = state[section].items.findIndex((item) => item.id === selectedTrigger);

      return {
        ...state,
        [section]: {
          ...state[section],
          items: [
            ...state[section].items.slice(0, triggerIndex),
            {
              ...state[section].items[triggerIndex],
              ...data,
            },
            ...state[section].items.slice(triggerIndex + 1, state.length),
          ],
        },
      };
    }
    default:
      return state;
  }
};

export default triggersReducer;

export default {
  root: {
    minHeight: 70,
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    '&$selected': {
      color: '#03a9f4',
      fontWeight: 700,
    },
  },
  wrapper: {
    flexDirection: 'row',
    justifyContent: 'left',
  },
  labelIcon: {
    minHeight: 77,
    '& $wrapper :first-child': {
      marginBottom: 0,
      marginRight: '0.5em',
    },
  },
};

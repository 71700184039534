import React, {
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { makeStyles } from '@material-ui/core/styles';
import {
  Tabs,
  Tab,
} from '@material-ui/core';
import {
  Palette,
  Visibility,
  MovieFilter,
  Gesture,
  Chat,
  CameraAlt,
} from '@material-ui/icons';
import { tabsSelectors } from '../store/selectors/tabs';
import { changeTab } from '../store/actions/tabs';
import { ignoreTriggersList } from '../common/Utils';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    height: '100%',
  },
}));

const tabsList = [{
  label: 'Color Palette',
  key: 'color-palette',
  icon: <Palette />,
}, {
  label: 'Animations',
  key: 'animations',
  icon: <Visibility />,
}, {
  label: 'Triggers',
  key: 'triggers',
  icon: <MovieFilter />,
}, {
  label: 'Phrasing',
  key: 'phrasing',
  icon: <Gesture />,
}, {
  label: 'Camera',
  key: 'camera',
  icon: <CameraAlt />,
}, {
  label: 'Moments',
  key: 'moments',
  icon: <Chat />,
}];

function VerticalTabs({
  activeTab,
  changeTab,
  isScriptLoaded,
}) {
  const classes = useStyles();

  useEffect(() => {
    if (isScriptLoaded) {
      const {
        Events: {
          INIT_COMPLETE,
        },
        EventBus,
      } = window.MUSICEYES;

      EventBus.on(INIT_COMPLETE, () => ignoreTriggersList(activeTab));
    }
  }, [isScriptLoaded, activeTab]);

  function handleTabChange(event, tabName) {
    const {
      key: tabKey,
    } = tabsList.find(({ key }) => key === tabName);

    changeTab(tabKey);
    ignoreTriggersList(tabName);

    // Force player resizing every tab change.
    setTimeout(() => window.dispatchEvent(new Event('resize')));
  }

  return (
    <div className={classes.root}>
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={activeTab}
        onChange={handleTabChange}
      >
        {tabsList.map(({ label, key, icon }) => (
          <Tab
            label={label}
            value={key}
            key={key}
            icon={icon}
          />
        ))}
      </Tabs>
    </div>
  );
}

VerticalTabs.propTypes = {
  changeTab: PropTypes.func.isRequired,
  activeTab: PropTypes.string.isRequired,
  isScriptLoaded: PropTypes.bool.isRequired,
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
  changeTab,
}, dispatch);

const mapStateToProps = (state) => ({
  activeTab: tabsSelectors.getActiveTab(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(VerticalTabs);

import React, { Component, createRef } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import {
  Box,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Rnd } from 'react-rnd';
import runMusaicApp from '../common/MusaicApp';
import { tabsSelectors } from '../store/selectors/tabs';
import { animationsSelectors } from '../store/selectors/animations';
import timelineSelectors from '../store/selectors/timeline';
import Playback from '../services/Playback';
import {
  changeAnimation,
} from '../store/actions/animations';
import {
  changeTrigger,
} from '../store/actions/triggers';
import {
  changeMoment,
} from '../store/actions/moments';
import {
  changeCamera,
} from '../store/actions/camera';

import { MusaicDataContext } from '../contexts/MusaicDataContext';

const styles = () => ({
  player: {
    position: 'relative',
    width: '100%',
    height: '100%',
    background: '#0b0c12',
  },
  stageContainer: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    overflow: 'hidden',
  },
  draggableContainer: {
    bottom: 0,
    zIndex: 1,
  },
  cursor: {
    position: 'absolute',
    top: 0,
    left: '50%',
    bottom: 0,
    borderLeft: '2px dashed rgba(255, 255, 255, 0.5)',
    paddingRight: 15,
    marginLeft: 15,
  },
});


class Player extends Component {
  constructor(props) {
    super(props);

    this.state = {
      visibleRange: {},
    };

    this.playerInterval = null;
    this.playerRef = createRef();
    this.handleOnDragStop = this.handleOnDragStop.bind(this);
  }

  componentDidMount() {
    const {
      onLoad,
    } = this.props;

    Playback.addPlaybackCallback((visibleRange) => {
      this.setState({
        visibleRange,
      });
    });

    this.prevContext = this.context;
  }

  componentDidUpdate() {
    const { onLoad } = this.props;
    if (this.prevContext !== this.context) {
      runMusaicApp(onLoad, this.context);
    }
    this.prevContext = this.context;
  }

  handleOnDragStop(event, { x }) {
    const {
      activeTab,
      changeAnimation,
      changeMoment,
      changeTrigger,
      selectedAnimation,
      selectedMoment,
      selectedTimelineItem,
      selectedTriggerId,
      changeCamera,
      selectedCamera,
    } = this.props;

    const {
      startTime,
      transitionDuration,
    } = selectedTimelineItem;
    const delta = startTime - x;
    const data = {
      startTime: x,
      ...delta > 0 && {
        transitionDuration: delta / 10 + transitionDuration,
      },
    };


    switch (activeTab) {
      case 'triggers':
        return changeTrigger({
          selectedTriggerId,
          data: {
            startTime: x,
          },
        });
      case 'camera':
        return changeCamera({
          selectedCamera,
          data,
        });
      case 'moments':
        return changeMoment({
          selectedMoment,
          data,
        });
      default: {
        return changeAnimation({
          selectedAnimation,
          data,
        });
      }
    }
  }

  render() {
    const {
      activeTab,
      classes,
      selectedTimelineItem,
    } = this.props;

    const {
      visibleRange,
    } = this.state;

    const cursorTime = selectedTimelineItem
      ? (visibleRange.current / 6) - selectedTimelineItem.startTime
      : 0;

    const displayCursor = selectedTimelineItem
      ? selectedTimelineItem.startTime >= visibleRange.left && selectedTimelineItem.startTime <= visibleRange.right
      : false;

    const isCursorVisible = (activeTab === 'animations' || activeTab === 'color-palette') && selectedTimelineItem;

    return (
      <Box
        ref={this.playerRef}
        className={`Player PlayerEmbedded ${classes.player}`}
      >
        <Box className={`StageContainer js-stage-container ${classes.stageContainer}`}>
          <canvas className="js-three-stage" />
        </Box>
        <Box>
          {isCursorVisible && (
            <Rnd
              bounds="parent"
              dragAxis="x"
              enableResizing={false}
              className={classes.draggableContainer}
              onDragStop={this.handleOnDragStop}
              position={{
                x: selectedTimelineItem.startTime,
                y: 0,
              }}
            >
              <Box className={classes.cursor} />
            </Rnd>
          )}
        </Box>
      </Box>
    );
  }
}

Player.propTypes = {
  classes: PropTypes.shape({
    player: PropTypes.string,
    stageContainer: PropTypes.string,
    cursor: PropTypes.string,
    draggableContainer: PropTypes.string,
  }).isRequired,
  onLoad: PropTypes.func.isRequired,
  selectedTimelineItem: PropTypes.shape({
    startTime: PropTypes.number,
    transitionDuration: PropTypes.number,
  }),
};

Player.defaultProps = {
  selectedTimelineItem: null,
};

Player.contextType = MusaicDataContext

const mapStateToProps = (state) => ({
  activeTab: tabsSelectors.getActiveTab(state),
  selectedTriggerId: timelineSelectors.getSelectedTriggerId(state),
  selectedAnimation: timelineSelectors.getSelectedAnimation(state),
  selectedMoment: timelineSelectors.getSelectedMoment(state),
  selectedCamera: timelineSelectors.getSelectedCamera(state),
  selectedTimelineItem: timelineSelectors.getSelectedTimelineItem(state),
  currentAnimation: animationsSelectors.getCurrentAnimation(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  changeAnimation,
  changeTrigger,
  changeMoment,
  changeCamera,
}, dispatch);


export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Player));

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Grid,
  Typography,
} from '@material-ui/core';
import Select from '../../../common/Select';
import ColorPicker from '../../../common/ColorPicker';
import backgroundColorStyles from '../../../common/BackgroundColorStyles';

class CustomizeBackground extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modalTitle: '',
      isModalOpened: false,
      isBackgroundColorChanged: false,
      isStyleChanged: false,
    };

    this.handleBackgroundColorChange = this.handleBackgroundColorChange.bind(this);
  }

  componentDidMount() {
    const {
      createTrigger,
    } = this.props;

    createTrigger('background', { startTime: 0 });
  }

  handleBackgroundColorChange(data) {
    const {
      isBackgroundColorChanged,
    } = this.state;

    const {
      changeTrigger,
      currentTrigger,
    } = this.props;

    if (!isBackgroundColorChanged) {
      this.setState({
        isBackgroundColorChanged: true,
      });
    }

    changeTrigger({
      section: 'background',
      data,
      triggerName: 'backgroundColor',
      selectedTrigger: currentTrigger.id,
    });
  }

  handleStyleChange(style) {
    const {
      isStyleChanged,
    } = this.state;

    const {
      changeTrigger,
      currentTrigger,
    } = this.props;

    if (!isStyleChanged) {
      this.setState({
        isStyleChanged: true,
      });
    }

    changeTrigger({
      section: 'background',
      data: {
        style,
      },
      triggerName: 'backgroundColor',
      selectedTrigger: currentTrigger.id,
    });
  }

  render() {
    const {
      backgroundColors,
      onStepDone,
      currentTrigger,
    } = this.props;

    const {
      modalTitle,
      isModalOpened,
    } = this.state;

    return (
      <Box>
        <Typography>
          Next let&apos;s pick a background color and shading style:
        </Typography>
        <Grid container>
          <Grid item xs={6}>
            <ColorPicker
              colors={backgroundColors}
              value={currentTrigger.backgroundColor ? currentTrigger.backgroundColor.color : 0}
              onSelectionChange={(color) => this.handleBackgroundColorChange({ color })}
            />
          </Grid>
          <Grid item xs={6}>
            <Select
              value={currentTrigger.backgroundColor ? currentTrigger.backgroundColor.style : ''}
              options={backgroundColorStyles}
              onChange={(style) => this.handleBackgroundColorChange({ style })}
              label="Style"
            />
          </Grid>
        </Grid>
        <Box display="flex" justifyContent="center">
          <Button variant="contained" color="primary" onClick={onStepDone}>
            Done
          </Button>
        </Box>
      </Box>
    );
  }
}

CustomizeBackground.propTypes = {
  currentTrigger: PropTypes.shape({
    id: PropTypes.number,
    backgroundColor: PropTypes.shape({
      color: PropTypes.number,
      style: PropTypes.string,
    }),
  }),
  color: PropTypes.number.isRequired,
  style: PropTypes.string.isRequired,
  onStepDone: PropTypes.func.isRequired,
  createTrigger: PropTypes.func.isRequired,
  backgroundColors: PropTypes.arrayOf(
    PropTypes.string,
  ).isRequired,
};

CustomizeBackground.defaultProps = {
  currentTrigger: {
    backgroundColor: {
      color: 0,
      style: '',
    },
  },
};

export default CustomizeBackground;

import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Typography,
} from '@material-ui/core';
import ColorPicker from '../../../common/ColorPicker';
import ShapesPicker from '../../../common/ShapesPicker';


function ColorsAndShapes({
  color,
  isDisabled,
  noteColors,
  onChange,
  shape,
}) {
  return (
    <Grid container>
      <Grid item xs={6}>
        <Typography variant="h6" color="primary">
          Colors
        </Typography>
        <ColorPicker
          colors={noteColors}
          value={color}
          onSelectionChange={(data) => onChange({ color: data })}
          disabled={isDisabled}
        />
      </Grid>
      <Grid item xs={6}>
        <Typography variant="h6" color="primary">
          Shapes
        </Typography>
        <ShapesPicker
          color={noteColors[color]}
          value={shape}
          onChange={(data) => onChange({ shape: data })}
          disabled={isDisabled}
        />
      </Grid>
    </Grid>
  );
}

ColorsAndShapes.propTypes = {
  color: PropTypes.number.isRequired,
  isDisabled: PropTypes.bool,
  noteColors: PropTypes.arrayOf(
    PropTypes.string,
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  shape: PropTypes.string.isRequired,
};

ColorsAndShapes.defaultProps = {
  isDisabled: false,
};

export default ColorsAndShapes;

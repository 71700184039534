import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import CollapsiblePanel from '../../../../common/CollapsiblePanel';
import NoteGlowPanel from './NoteGlowPanel';
import NoteShaftsPanel from './NoteShaftsPanel';
import NoteAuraPanel from './NoteAuraPanel';
import GodraysPanel from './GodraysPanel';


function NotesPanels({
  activePanels,
  values: {
    noteGlow = {},
    noteShafts = {},
    noteAura = {},
    godrays = {},
  },
  onChange,
  onPanelCollapse,
  layers,
  disabled,
  onTriggerClick,
}) {
  return (
    <Box>
      <CollapsiblePanel
        onClick={() => onTriggerClick('noteGlow')}
        title="Note glow"
        isExpanded={activePanels.includes('noteGlow')}
        handleChange={() => onPanelCollapse('noteGlow')}
        disabled={disabled}
      >
        <NoteGlowPanel
          layers={layers}
          values={noteGlow}
          onChange={(value) => onChange('noteGlow', value)}
        />
      </CollapsiblePanel>
      <CollapsiblePanel
        onClick={() => onTriggerClick('noteShafts')}
        title="Note shafts"
        isExpanded={activePanels.includes('noteShafts')}
        handleChange={() => onPanelCollapse('noteShafts')}
        disabled={disabled}
      >
        <NoteShaftsPanel
          layers={layers}
          values={noteShafts}
          onChange={(value) => onChange('noteShafts', value)}
        />
      </CollapsiblePanel>
      <CollapsiblePanel
        onClick={() => onTriggerClick('noteAura')}
        title="Note aura"
        isExpanded={activePanels.includes('noteAura')}
        handleChange={() => onPanelCollapse('noteAura')}
        disabled={disabled}
      >
        <NoteAuraPanel
          layers={layers}
          values={noteAura}
          onChange={(value) => onChange('noteAura', value)}
        />
      </CollapsiblePanel>
      <CollapsiblePanel
        onClick={() => onTriggerClick('godrays')}
        title="Godrays"
        isExpanded={activePanels.includes('godrays')}
        handleChange={() => onPanelCollapse('godrays')}
        disabled={disabled}
      >
        <GodraysPanel
          layers={layers}
          values={godrays}
          onChange={(value) => onChange('godrays', value)}
        />
      </CollapsiblePanel>
    </Box>
  );
}

NotesPanels.propTypes = {
  values: PropTypes.shape({
    noteGlow: PropTypes.object,
    noteShafts: PropTypes.object,
    noteAura: PropTypes.object,
    godrays: PropTypes.object,
  }),
  activePanels: PropTypes.arrayOf(PropTypes.string).isRequired,
  onPanelCollapse: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onTriggerClick: PropTypes.func.isRequired,
  layers: PropTypes.arrayOf(PropTypes.object).isRequired,
  disabled: PropTypes.bool.isRequired,
};

NotesPanels.defaultProps = {
  values: {
    noteGlow: {},
    noteShafts: {},
    noteAura: {},
    godrays: {},
  },
};

export default NotesPanels;

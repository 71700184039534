import { Radio } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';


const Shape = withStyles(() => ({
  root: ({ shapecolor }) => ({
    width: 28,
    height: 28,
    borderRadius: 5,
    margin: 4,
    padding: 0,
    color: shapecolor,
    transition: 'color 0.25s ease',
    '&$checked': {
      width: 24,
      height: 24,
      color: shapecolor,
      border: '2px solid rgba(0, 0, 0, 0.12)',
    },
  }),
  checked: ({ shapecolor }) => ({
    width: 24,
    height: 24,
    color: shapecolor,
    border: '2px solid rgba(0, 0, 0, 0.12)',
  }),
  disabled: {
    color: '#c5c5c5 !important',
    '&$checked': {
      boxShadow: 'none',
    },
  },
}))(Radio);

export default Shape;

const PREFIX = '[MOMENTS]';

export const CHANGE_MOMENT = `${PREFIX} CHANGE MOMENT`;
export const changeMoment = (payload) => ({
  type: CHANGE_MOMENT,
  payload,
});

export const UPDATE_MOMENTS = `${PREFIX} UPDATE MOMENTS`;
export const updateMoments = (payload) => ({
  type: UPDATE_MOMENTS,
  payload,
});

export const REMOVE_MOMENT = `${PREFIX} REMOVE MOMENT`;
export const removeMoment = (payload) => ({
  type: REMOVE_MOMENT,
  payload,
});

function MusaicApp(targetObj, idHash, mode) {
  this.id_hash = idHash;
  this.mode = mode || 'VIEW';
  this.initializing = false;

  if (!window.musaic) {
    window.musaic = new window.Musaic(targetObj);
  }

  window.musaic.init(() => {
    if (this.initializing) {
      return;
    }

    this.initializing = true;
  });
}

function runMusaicApp(onLoad, musaicContext) {
  // Default musaic context
  const context = musaicContext || { dataEndpoint: 'dvorak.json' };
  context.dataEndpoint = context.dataEndpoint || 'dvorak.json';

  if (window.playerConfig === undefined) {
    window.playerConfig = {
      mediaBaseUrl: process.env.REACT_APP_MEDIA_BASE_URL,
      imagesBaseUrl: process.env.REACT_APP_IMAGES_BASE_URL,
      modelsBaseUrl: process.env.REACT_APP_MODELS_BASE_URL,
      soundsBaseUrl: process.env.REACT_APP_SOUNDS_BASE_URL,
      fontsBaseUrl: process.env.REACT_APP_FONTS_BASE_URL,
      dataEndpoint: context.dataEndpoint,
      mode: 'edit',
      allowBrowserMIDI: false,
      debug: false,
      useNoSleep: false,
    };
  }

  const script = document.createElement('script');
  script.src = process.env.REACT_APP_MAIN_BUNDLE_URL;
  script.onload = () => {
    // eslint-disable-next-line no-new
    new MusaicApp(null, '6ZWgDqxFYzrHG', 'CREATE');

    onLoad();
  };

  document.body.append(script);
}

export default runMusaicApp;

import {
  CHANGE_NOTE_COLOR,
  CHANGE_BACKGROUND_COLOR,
  UPDATE_SETTINGS,
} from '../actions/settings';

const defaultColorPalette = [
  '#11bfee',
  '#b7fc00',
  '#ffd401',
  '#ee817e',
  '#48a4f8',
  '#a7c903',
  '#febb00',
  '#e46d53',
  '#1a77ef',
  '#7ea814',
  '#f49426',
  '#d83f20',
  '#0f3daa',
  '#698917',
  '#ff7701',
  '#c02d0f',
];

export const initialState = {
  noteColors: defaultColorPalette,
  backgroundColors: defaultColorPalette,
};

const settingsReducer = (state = initialState, action) => {
  const { payload } = action;

  switch (action.type) {
    case CHANGE_NOTE_COLOR: {
      const { index, color } = payload;
      const { noteColors } = state;
      return {
        ...state,
        noteColors: [
          ...noteColors.slice(0, index),
          color,
          ...noteColors.slice(index + 1, noteColors.length),
        ],
      };
    }
    case CHANGE_BACKGROUND_COLOR: {
      const { index, color } = payload;
      const { backgroundColors } = state;
      return {
        ...state,
        backgroundColors: [
          ...backgroundColors.slice(0, index),
          color,
          ...backgroundColors.slice(index + 1, backgroundColors.length),
        ],
      };
    }
    case UPDATE_SETTINGS:
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
};

export default settingsReducer;

import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
  ruler: {
    display: 'flex',
    flexWrap: 'nowrap',
    height: 30,
  },
  unit: {
    display: 'flex',
    flexDirection: 'column',
    // minWidth: 100,
    borderLeft: '1px solid #C5C5C5',
    borderRight: '1px solid #C5C5C5',
    marginBottom: 10,
  },
  innerUnits: {
    display: 'flex',
    flexWrap: 'nowrap',
  },
  innerUnit: {
    // minWidth: 20,
    height: 10,
    borderRight: '1px solid #C5C5C5',
  },
  label: {
    margin: '0 2px',
    fontSize: 14,
    fontWeight: 700,
  },
}));

const TIMESTAMP_TICKS = 5;

function getLabel(index) {
  const interval = index * TIMESTAMP_TICKS;
  const minutes = `0${Math.floor(interval / 60)}`.slice(-2);
  const seconds = `0${interval % 60}`.slice(-2);

  return `${minutes}:${seconds}`;
}

function Ruler({
  pixelsPerSecond,
  duration,
}) {
  const classes = useStyles();

  const durationInSeconds = Math.floor(duration / 1000);
  console.log(duration, durationInSeconds, durationInSeconds / TIMESTAMP_TICKS - 1);

  return (
    <div className={classes.ruler}>
      {new Array(durationInSeconds).fill(false).map((_, index) => {
        return (
          <div className={classes.unit} key={index}>
            <div className={classes.innerUnits}>
              {new Array(Math.floor(durationInSeconds / TIMESTAMP_TICKS - 1)).fill(false).map((_, innerIndex) => (
                <div
                  className={classes.innerUnit}
                  key={innerIndex}
                // style={{ width:  }}
                />
              ))}
            </div>
            <div className={"testlabel"}>
              {getLabel(index)}
            </div>
          </div>
        );
      })}
    </div>
  );
}

Ruler.propTypes = {
  duration: PropTypes.number,
  pixelsPerSecond: PropTypes.number,
};

Ruler.defaultProps = {
  duration: 100,
  pixelsPerSecond: 50,
};

export default Ruler;

export default {
  root: {
    right: 'auto',
    left: 0,
  },
  vertical: {
    width: 4,
  },
  colorSecondary: {
    backgroundColor: '#03a9f4',
  },
};

import { createMuiTheme } from '@material-ui/core/styles';
import {
  lightBlue,
  green,
} from '@material-ui/core/colors';
import MuiAppBar from './overrides/MuiAppBar';
import MuiExpansionPanel from './overrides/MuiExpansionPanel';
import MuiExpansionPanelSummary from './overrides/MuiExpansionPanelSummary';
import MuiTab from './overrides/MuiTab';
import MuiTabs from './overrides/MuiTabs';
import PrivateTabIndicator from './overrides/PrivateTabIndicator';
import MuiExpansionPanelDetails from './overrides/MuiExpansionPanelDetails';

export default createMuiTheme({
  palette: {
    primary: lightBlue,
    secondary: green,
    text: {
      secondary: '#a2a1a1',
    },
  },
  overrides: {
    MuiAppBar,
    MuiExpansionPanel,
    MuiExpansionPanelDetails,
    MuiExpansionPanelSummary,
    MuiTab,
    MuiTabs,
    PrivateTabIndicator,
  },
});

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  setAnimationsDataStructure,
} from '../store/actions/timeline';
import { MusaicDataProvider } from './MusaicDataContext';


function sortTracks(tracks) {
  return tracks.sort((a, b) => {
    if (a.order < b.order) {
      return -1;
    }
    if (a.order > b.order) {
      return 1;
    }

    return 0;
  });
}

class MusaicContext extends Component {
  constructor(props) {
    super(props);

    this.state = {
      musaic: {},
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData() {
    const {
      setAnimationsDataStructure,
    } = this.props;

    let useFallback = true
    let url = 'https://app.musiceyes.org/data/6ZWgDqxFYzrHG/view'
    let backup_url = 'dvorak.json'

    const processResponse = (response) => {
      response.json().then((data) => {
        this.setState({
          musaic: {
            ...data,
            tracks: sortTracks(data.tracks),
            dataEndpoint: url,
          },
        });
        setAnimationsDataStructure(data);
      })
    }

    console.log('fetching data')

    let fallbackOnError = (err) => {
      if (useFallback !== true) {
        throw new Error(err)
      } else {
        url = backup_url
        return fetch(url)
      }
    }
    fetch(url)
      .then((response) => {
        if (response.status !== 200) {
          return fallbackOnError(response)
        }
        return response
      }, fallbackOnError)
      .then(processResponse)
      .catch((err) => {
        throw new Error(err);
      });
  }

  render() {
    const {
      children,
    } = this.props;
    const {
      musaic,
    } = this.state;

    return (
      <MusaicDataProvider value={musaic}>
        {children}
      </MusaicDataProvider>
    );
  }
}

MusaicContext.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
    PropTypes.func,
  ]).isRequired,
  setAnimationsDataStructure: PropTypes.func.isRequired,
};
const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  setAnimationsDataStructure,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(MusaicContext);

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Box,
  Typography,
} from '@material-ui/core';
import { settingsSelectors } from '../store/selectors/settings';
import ColorPicker from '../common/ColorPicker';
import DetailsSection from '../common/DetailsSection';
import * as settingsActions from '../store/actions/settings';


function ColorPalette({
  noteColors,
  changeNoteColor,
  backgroundColors,
  changeBackgroundColor,
}) {
  const [noteColorIndex, handleNoteColorIndexChange] = useState(0);
  const [backgroundColorIndex, handleBackgroundColorIndexChange] = useState(0);

  return (
    <Box>
      <DetailsSection>
        <Typography variant="h6" color="primary">
          Note colors
        </Typography>
        <ColorPicker
          editable
          colors={noteColors}
          value={noteColorIndex}
          onSelectionChange={handleNoteColorIndexChange}
          onColorChange={(color, index) => changeNoteColor({ color, index })}
        />
      </DetailsSection>
      <DetailsSection>
        <Typography variant="h6" color="primary">
          Background colors
        </Typography>
        <ColorPicker
          editable
          colors={backgroundColors}
          value={backgroundColorIndex}
          onSelectionChange={handleBackgroundColorIndexChange}
          onColorChange={(color, index) => changeBackgroundColor({ color, index })}
        />
      </DetailsSection>
    </Box>
  );
}

ColorPalette.propTypes = {
  changeNoteColor: PropTypes.func.isRequired,
  noteColors: PropTypes.arrayOf(
    PropTypes.string,
  ).isRequired,
  changeBackgroundColor: PropTypes.func.isRequired,
  backgroundColors: PropTypes.arrayOf(
    PropTypes.string,
  ).isRequired,
};

const mapStateToProps = (state) => ({
  noteColors: settingsSelectors.getNoteColors(state),
  backgroundColors: settingsSelectors.getBackgroundColors(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  changeNoteColor: settingsActions.changeNoteColor,
  changeBackgroundColor: settingsActions.changeBackgroundColor,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ColorPalette);

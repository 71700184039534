// eslint-disable-next-line import/prefer-default-export
export const getCurrentTime = (currentTime) => {
  const minutes = `0${Math.floor(window.MUSICEYES.getCurrentTime() / 60)}`.slice(-2);
  const seconds = `0${Math.floor(currentTime % 60)}`.slice(-2);

  return currentTime ? `${minutes}:${seconds}` : null;
};

export const ignoreTriggersList = (tabName) => {
  const {
    setCameraAngle,
    updateIgnoreTriggersList,
  } = window.MUSICEYES;

  if (tabName !== 'camera' && tabName !== 'moments') {
    updateIgnoreTriggersList([
      'CAMANGL',
      'CAMROLL',
    ]);

    setCameraAngle({
      pos: {
        x: 0,
        y: 0,
        z: 500,
      },
      target: {
        x: 0,
        y: 0,
        z: 0,
      },
      roll: 0,
      phraseZoomFactor: 0,
    });
  } else {
    updateIgnoreTriggersList([]);
  }
};


/**
 * Returns a number whose value is limited to the given range.
 *
 * Example: limit the output of this computation to between 0 and 255
 * (x * 255).clamp(0, 255)
 *
 * @param {Number} min The lower boundary of the output range
 * @param {Number} max The upper boundary of the output range
 * @returns A number in the range [min, max]
 * @type Number
 */
export const clamp = (value, min, max) => Math.min(Math.max(value, min), max)



// requestAnimationFrame() shim by Paul Irish
export const requestAnimFrame = () => window.requestAnimationFrame
  || window.webkitRequestAnimationFrame
  || window.mozRequestAnimationFrame
  || window.oRequestAnimationFrame
  || window.msRequestAnimationFrame
  || ((callback, element) => {
    window.setTimeout(callback, 1000 / 60);
  });

/**
 * Behaves the same as setInterval except uses requestAnimationFrame()
 * where possible for better performance
 *
 * @param {function} fn The callback function
 * @param {int} delay The delay in milliseconds
 */
export const requestInterval = (fn, delay) => {
  if (!window.requestAnimationFrame
    && !window.webkitRequestAnimationFrame
    && !(window.mozRequestAnimationFrame && window.mozCancelRequestAnimationFrame)
    && !window.oRequestAnimationFrame && !window.msRequestAnimationFrame) {
    return window.setInterval(fn, delay);
  }

  let start = new Date().getTime();
  const handle = {};

  function loop() {
    const current = new Date().getTime();
    const delta = current - start;
    if (delta >= delay) {
      fn.call();
      start = new Date().getTime();
    }
    handle.value = requestAnimFrame(loop);
  }

  handle.value = requestAnimFrame(loop);
  return handle;
};

/**
 * Behaves the same as clearInterval except uses cancelRequestAnimationFrame() where
 * possible for better performance.
 *
 * @param {int|object} fn The callback function
 */
export const clearRequestInterval = (handle) => {
  if (window.cancelAnimationFrame) {
    window.cancelAnimationFrame(handle.value);
  }
  else if (window.webkitCancelAnimationFrame) {
    window.webkitCancelAnimationFrame(handle.value);
  } else if (window.webkitCancelRequestAnimationFrame) {
    window.webkitCancelRequestAnimationFrame(handle.value);
  } else if (window.mozCancelRequestAnimationFrame) {
    window.mozCancelRequestAnimationFrame(handle.value);
  } else if (window.oCancelRequestAnimationFrame) {
    window.oCancelRequestAnimationFrame(handle.value);
  } else if (window.msCancelRequestAnimationFrame) {
    window.msCancelRequestAnimationFrame(handle.value);
  } else {
    clearInterval(handle);
  }
};

const PREFIX = '[ANIMATIONS]';

export const CHANGE_ANIMATION = `${PREFIX} CHANGE ANIMATION`;
export const changeAnimation = (payload) => ({
  type: CHANGE_ANIMATION,
  payload,
});

export const UPDATE_ANIMATION = `${PREFIX} UPDATE_ANIMATION`;
export const updateAnimation = (payload) => ({
  type: UPDATE_ANIMATION,
  payload,
});

export const REMOVE_ANIMATION = `${PREFIX} REMOVE_ANIMATION`;
export const removeAnimation = (payload) => ({
  type: REMOVE_ANIMATION,
  payload,
});

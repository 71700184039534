import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles(({ palette }) => ({
  timelineRow: {
    position: 'relative',
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    background: '#F2F2F2',
    padding: 8,
    height: 48,
  },
  selectedTimelineRow: {
    background: '#FFF',
    fontWeight: 700,
    color: palette.primary[500],
  },
}));

function TimelineRow({
  children,
  justifyContent,
  onClick,
  onScroll,
  isSelected,
  className,
  onMouseOver,
}) {
  const classes = useStyles();
  return (
    // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
    <Box
      className={`
        timeline-row
        ${className}
        ${classes.timelineRow}
        ${isSelected ? classes.selectedTimelineRow : ''}
      `}
      display="flex"
      alignItems="center"
      flexGrow={1}
      justifyContent={justifyContent}
      p={2}
      onClick={onClick}
      onScroll={onScroll}
      onMouseOver={onMouseOver}
    >
      {children}
    </Box>
  );
}

TimelineRow.propTypes = {
  children: PropTypes.node.isRequired,
  justifyContent: PropTypes.string,
  onClick: PropTypes.func,
  onScroll: PropTypes.func,
  onMouseOver: PropTypes.func,
  isSelected: PropTypes.bool,
  className: PropTypes.string,
};

TimelineRow.defaultProps = {
  justifyContent: null,
  onClick: null,
  onScroll: null,
  onMouseOver: () => {},
  isSelected: false,
  className: '',
};

export default TimelineRow;

import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  IconButton,
} from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { makeStyles } from '@material-ui/core/styles';
import TimelineRow from './TimelineRow';

const useStyles = makeStyles(() => ({
  legend: {
    position: 'absolute',
    minHeight: '100%',
    paddingTop: 30,
    width: 200,
    borderRight: '1px solid rgba(0, 0, 0, 0.12)',
    zIndex: 1,
  },
  legendRow: {
    position: 'relative',
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
}));

function TimelineLegend({
  isSelected,
  items,
  onVisibilityChange,
  hasVisibilityIcon,
  visibleIndices,
}) {
  const classes = useStyles();

  return (
    <Box className={classes.legend}>
      {items.map((track, index) => (
        <TimelineRow
          justifyContent="space-between"
          key={track.name}
          isSelected={track.items && !!track.items.find(({ id }) => isSelected(id))}
        >
          {track.name}
          {hasVisibilityIcon && (
            <IconButton onClick={() => onVisibilityChange(index, track)}>
              {visibleIndices.includes(index) ? (
                <VisibilityIcon />
              ) : (
                <VisibilityOffIcon color="disabled" />
              )}
            </IconButton>
          )}
        </TimelineRow>
      ))}
    </Box>
  );
}

TimelineLegend.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
  hasVisibilityIcon: PropTypes.bool.isRequired,
  onVisibilityChange: PropTypes.func,
  isSelected: PropTypes.func,
  visibleIndices: PropTypes.arrayOf(
    PropTypes.number,
  ).isRequired,
};

TimelineLegend.defaultProps = {
  items: [],
  onVisibilityChange: () => {},
  isSelected: () => false,
};

export default TimelineLegend;

const PREFIX = '[CAMERA]';

export const CHANGE_CAMERA = `${PREFIX} CHANGE CAMERA`;
export const changeCamera = (payload) => ({
  type: CHANGE_CAMERA,
  payload,
});

export const REMOVE_CAMERA = `${PREFIX} REMOVE CAMERA`;
export const removeCamera = (payload) => ({
  type: REMOVE_CAMERA,
  payload,
});

const PREFIX = '[TRIGGERS]';


export const UPDATE_TRIGGERS = `${PREFIX} UPDATE_TRIGGERS`;
export const updateTriggers = (payload) => ({
  type: UPDATE_TRIGGERS,
  payload,
});

export const REMOVE_TRIGGER = `${PREFIX} REMOVE_TRIGGER`;
export const removeTrigger = (payload) => ({
  type: REMOVE_TRIGGER,
  payload,
});

export const UNDO_TRIGGERS_HISTORY = `${PREFIX} UNDO TRIGGERS HISTORY`;
export const undoTriggersHistory = () => ({
  type: UNDO_TRIGGERS_HISTORY,
});

export const REDO_TRIGGERS_HISTORY = `${PREFIX} REDO TRIGGERS HISTORY`;
export const redoTriggersHistory = () => ({
  type: REDO_TRIGGERS_HISTORY,
});

export const TOGGLE_ACTIVE_TRIGGERS = `${PREFIX} TOGGLE ACTIVE TRIGGERS`;
export const changeActiveTriggers = (payload) => ({
  type: TOGGLE_ACTIVE_TRIGGERS,
  payload,
});

export const CHANGE_TRIGGERS_PANEL = `${PREFIX} CHANGE TRIGGERS PANEL`;
export const changeTrigger = (payload) => ({
  type: CHANGE_TRIGGERS_PANEL,
  payload,
});

export const CHANGE_TRIGGER_ITEM = `${PREFIX} CHANGE TRIGGER ITEM`;
export const changeTriggerItem = (payload) => ({
  type: CHANGE_TRIGGER_ITEM,
  payload,
});

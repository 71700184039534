import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  RadioGroup,
} from '@material-ui/core';
import Shape from './Shape';


const shapesList = [{
  icon: '▬',
  label: 'Bar',
  key: 'bar',
}, {
  icon: '●',
  label: 'Dot',
  key: 'dot',
}, {
  icon: '●',
  label: 'Circle',
  key: 'circle',
}, {
  icon: '●',
  label: 'Oval',
  key: 'oval',
}, {
  icon: '●',
  label: 'Drop',
  key: 'drop',
}, {
  icon: '●',
  label: 'Reverse Drop',
  key: 'dropReverse',
}, {
  icon: '♦',
  label: 'Diamond',
  key: 'diamond',
}, {
  icon: '♦',
  label: 'Diamond long',
  key: 'diamondLong',
}, {
  icon: '►',
  label: 'Triangle',
  key: 'triangle',
}, {
  icon: '◀',
  label: 'Reverse Triangle',
  key: 'triangleReverse',
}, {
  icon: '⭓',
  label: 'Pentagon',
  key: 'pentagon',
}, {
  icon: '⬣',
  label: 'Hexagon',
  key: 'hexagon',
}];

function ShapesPicker({
  color,
  value,
  onChange,
  disabled,
}) {
  return (
    <Grid container>
      <Grid item>
        <RadioGroup
          value={value}
          onChange={({ target }) => onChange(target.value)}
        >
          <Grid container>
            {shapesList.map((shape) => (
              <Grid item xs={3} key={shape.key}>
                <Shape
                  checkedIcon={shape.icon}
                  shapecolor={color}
                  icon={shape.icon}
                  value={shape.key}
                  disabled={disabled}
                />
              </Grid>
            ))}
          </Grid>
        </RadioGroup>
      </Grid>
    </Grid>
  );
}

ShapesPicker.propTypes = {
  color: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

ShapesPicker.defaultProps = {
  disabled: false,
};

export default ShapesPicker;

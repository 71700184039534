/* eslint-disable import/prefer-default-export */
import { createSelector } from 'reselect';

export const revisionsSelectors = {
  getCurrentSnapshot: createSelector([
    ({ animations }) => animations.present,
    ({ triggers }) => triggers.present,
    ({ timeline }) => timeline.present,
    ({ settings }) => settings.present,
    ({ moments }) => moments.present,
  ], (animations, triggers, timeline, settings, moments) => ({
    animations,
    triggers,
    timeline,
    settings,
    moments,
  })),
  getRevisionsList: (state) => state.revisions.revisions,
};

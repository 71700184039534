import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Typography,
} from '@material-ui/core';
import ContrastModal from './ContrastModal';

class ChooseShapes extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modalTitle: '',
      isModalOpened: false,
      changedTracks: new Set(),
    };

    this.handleOnChange = this.handleOnChange.bind(this);
    this.handleDoneClick = this.handleDoneClick.bind(this);
  }

  componentDidMount() {
    const {
      Events: {
        NOTE_CLICK,
      },
      EventBus,
      useNoteHoverCursor: enableHoveringNotes,
    } = window.MUSICEYES;

    enableHoveringNotes(true);

    EventBus.on(NOTE_CLICK, ({ trackName, trackId }) => {
      this.setState(({ changedTracks }) => ({
        modalTitle: trackName,
        isModalOpened: true,
        changedTracks: new Set([
          ...changedTracks,
          trackId,
        ]),
      }));
    });
  }

  handleOnChange(data) {
    const {
      currentAnimation,
      changeAnimation,
    } = this.props;

    changeAnimation({
      selectedAnimation: currentAnimation.id,
      data,
    });
  }

  handleDoneClick() {
    this.setState({
      isModalOpened: false,
    });
  }

  render() {
    const {
      changedTracks,
      modalTitle,
      isModalOpened,
    } = this.state;

    const {
      noteColors,
      goToNextStep,
      currentAnimation,
    } = this.props;

    return (
      <Box display="flex" flexDirection="column">
        <Box paddingBottom={2}>
          <Typography>
            Right now all notes are visible all the time, which can be overwhelming to look at...
          </Typography>
        </Box>
        <Box paddingTop={2}>
          <Typography>
            Make the animation more interesting by changing how notes look in the future and in the past. Tap any note to get started!
          </Typography>
          {changedTracks.size >= 3 && (
            <Box display="flex" justifyContent="center" paddingTop={2}>
              <Button variant="contained" color="primary" onClick={goToNextStep}>
                Next step
              </Button>
            </Box>
          )}
        </Box>
        <ContrastModal
          title={modalTitle}
          isOpened={isModalOpened}
          noteColors={noteColors}
          onDoneClick={this.handleDoneClick}
          afterPlay={currentAnimation.afterPlay}
          beforePlay={currentAnimation.beforePlay}
          onPlay={currentAnimation.onPlay}
          onChange={this.handleOnChange}
        />
      </Box>
    );
  }
}

ChooseShapes.propTypes = {
  noteColors: PropTypes.arrayOf(
    PropTypes.string,
  ).isRequired,
  goToNextStep: PropTypes.func.isRequired,
  changeAnimation: PropTypes.func.isRequired,
  currentAnimation: PropTypes.shape({
    id: PropTypes.number,
    onPlay: PropTypes.string,
    afterPlay: PropTypes.string,
    beforePlay: PropTypes.string,
  }),
};

ChooseShapes.defaultProps = {
  currentAnimation: {
    onPlay: '',
    afterPlay: '',
    beforePlay: '',
  },
};

export default ChooseShapes;

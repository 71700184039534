import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Typography,
} from '@material-ui/core';
import VisualEmphasisModal from './VisualEmphasisModal';


class VisualEmphasis extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalTitle: '',
      isModalOpened: false,
      changedTracks: new Set(),
    };

    this.handleOnChange = this.handleOnChange.bind(this);
    this.handleOnDoneClick = this.handleOnDoneClick.bind(this);
  }

  componentDidMount() {
    const {
      createTrigger,
    } = this.props;

    const {
      Events: {
        NOTE_CLICK,
      },
      EventBus,
      useNoteHoverCursor: enableHoveringNotes,
    } = window.MUSICEYES;

    createTrigger('light', { startTime: 0 });

    enableHoveringNotes(true);

    EventBus.on(NOTE_CLICK, ({ trackName, trackId }) => {
      this.setState(({ changedTracks }) => ({
        modalTitle: trackName,
        isModalOpened: true,
        changedTracks: new Set([
          ...changedTracks,
          trackId,
        ]),
      }));
    });
  }

  handleOnChange(triggerName, data) {
    const {
      currentTrigger,
      changeTrigger,
    } = this.props;

    changeTrigger({
      data,
      section: 'light',
      selectedTrigger: currentTrigger.id,
      triggerName,
    });
  }

  handleOnDoneClick() {
    this.setState({
      isModalOpened: false,
    });
  }

  render() {
    const {
      onStepDone,
      currentTrigger,
    } = this.props;

    const {
      modalTitle,
      isModalOpened,
      changedTracks,
    } = this.state;

    return (
      <Box>
        <Typography>
          Now let&apos;s help guide the viewer&apos;s attention by making one of the tracks more visually prominent. Click on a track and add either a Note Glow, Note Aura or Godrays effect.
        </Typography>
        {changedTracks.size >= 3 && (
          <Box display="flex" justifyContent="center">
            <Button variant="contained" color="primary" onClick={onStepDone}>
              Next step
            </Button>
          </Box>
        )}
        <VisualEmphasisModal
          title={modalTitle}
          beforePlay={currentTrigger.beforePlay}
          onPlay={currentTrigger.onPlay}
          afterPlay={currentTrigger.afterPlay}
          onChange={this.handleOnChange}
          onDoneClick={this.handleOnDoneClick}
          isOpened={isModalOpened}
        />
      </Box>
    );
  }
}


VisualEmphasis.propTypes = {
  onStepDone: PropTypes.func.isRequired,
  createTrigger: PropTypes.func.isRequired,
  changeTrigger: PropTypes.func.isRequired,
  currentTrigger: PropTypes.shape({
    id: PropTypes.number,
    beforePlay: PropTypes.string,
    afterPlay: PropTypes.string,
    onPlay: PropTypes.string,
  }),
};

VisualEmphasis.defaultProps = {
  currentTrigger: {
    id: 0,
    beforePlay: null,
    afterPlay: null,
    onPlay: null,
  },
};

export default VisualEmphasis;

import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
} from '@material-ui/core';
import Select from '../../../../common/Select';
import ColorPicker from '../../../../common/ColorPicker';
import Slider from '../../../../common/Slider';


const backgroundHighlightStyles = [
  { key: '10', label: 'Instantly' },
  { key: '9', label: 'Super fast' },
  { key: '8', label: 'Very fast' },
  { key: '7', label: 'Faster' },
  { key: '6', label: 'A bit fast' },
  { key: '5', label: 'Default' },
  { key: '4', label: 'A bit slow' },
  { key: '3', label: 'Slower' },
  { key: '2', label: 'Very slow' },
  { key: '1', label: 'Super slow' },
];

function BackgroundFlashPanel({
  backgroundColors,
  onChange,
  values,
}) {
  return (
    <div>
      <Grid container>
        <Grid item xs={6}>
          <ColorPicker
            colors={backgroundColors}
            value={values.color}
            onSelectionChange={(color) => onChange({ color })}
          />
        </Grid>
        <Grid item xs={6}>
          <Select
            value={values.intensity}
            options={backgroundHighlightStyles}
            onChange={(intensity) => onChange({ intensity })}
            label="Intensity"
          />
        </Grid>
        <Grid item xs={12}>
          <Slider
            title="Transition"
            labelLeft="Fast"
            labelRight="Slow"
            value={values.transitionDuration}
            onChange={(transitionDuration) => onChange({ transitionDuration })}
            valueLabelDisplay="off"
          />
        </Grid>
      </Grid>
    </div>
  );
}

BackgroundFlashPanel.propTypes = {
  onChange: PropTypes.func.isRequired,
  values: PropTypes.shape({
    color: PropTypes.number,
    transitionDuration: PropTypes.number,
    intensity: PropTypes.string,
  }),
  backgroundColors: PropTypes.arrayOf(
    PropTypes.string,
  ).isRequired,
};

BackgroundFlashPanel.defaultProps = {
  values: {
    color: 0,
    transitionDuration: 0,
    intensity: null,
  },
};

export default BackgroundFlashPanel;

import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import CollapsiblePanel from '../../../../common/CollapsiblePanel';
import ColorPanel from './ColorPanel';
import FlashPanel from './FlashPanel';
import ParticlePanel from './ParticlePanel';
import ShimmerPanel from './ShimmerPanel';


function BackgroundPanels({
  activePanels,
  values,
  backgroundColors,
  onChange,
  onPanelCollapse,
  disabled,
  onTriggerClick,
}) {
  return (
    <Box>
      <CollapsiblePanel
        onClick={() => onTriggerClick('backgroundColor')}
        title="Background color"
        isExpanded={activePanels.includes('backgroundColor')}
        handleChange={() => onPanelCollapse('backgroundColor')}
        disabled={disabled}
      >
        <ColorPanel
          values={values.backgroundColor}
          onChange={(backgroundColor) => onChange('backgroundColor', backgroundColor)}
          backgroundColors={backgroundColors}
        />
      </CollapsiblePanel>
      <CollapsiblePanel
        onClick={() => onTriggerClick('backgroundFlash')}
        title="Background flash"
        isExpanded={activePanels.includes('backgroundFlash')}
        handleChange={() => onPanelCollapse('backgroundFlash')}
        disabled={disabled}
      >
        <FlashPanel
          values={values.backgroundFlash}
          onChange={(backgroundFlash) => onChange('backgroundFlash', backgroundFlash)}
          backgroundColors={backgroundColors}
        />
      </CollapsiblePanel>
      <CollapsiblePanel
        onClick={() => onTriggerClick('backgroundParticle')}
        title="Particle field"
        isExpanded={activePanels.includes('backgroundParticle')}
        handleChange={() => onPanelCollapse('backgroundParticle')}
        disabled={disabled}
      >
        <ParticlePanel
          values={values.backgroundParticle}
          onChange={(backgroundParticle) => onChange('backgroundParticle', backgroundParticle)}
          backgroundColors={backgroundColors}
        />
      </CollapsiblePanel>
      <CollapsiblePanel
        onClick={() => onTriggerClick('backgroundShimmer')}
        title="Background shimmer"
        isExpanded={activePanels.includes('backgroundShimmer')}
        handleChange={() => onPanelCollapse('backgroundShimmer')}
        disabled={disabled}
      >
        <ShimmerPanel
          values={values.backgroundShimmer}
          onChange={(backgroundShimmer) => onChange('backgroundShimmer', backgroundShimmer)}
          backgroundColors={backgroundColors}
        />
      </CollapsiblePanel>
    </Box>
  );
}

BackgroundPanels.propTypes = {
  values: PropTypes.shape({
    backgroundColor: PropTypes.object,
    backgroundFlash: PropTypes.object,
    backgroundParticle: PropTypes.object,
    backgroundShimmer: PropTypes.object,
  }),
  backgroundColors: PropTypes.arrayOf(PropTypes.string).isRequired,
  activePanels: PropTypes.arrayOf(PropTypes.string).isRequired,
  onPanelCollapse: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onTriggerClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

BackgroundPanels.defaultProps = {
  values: {
    backgroundColor: {},
    backgroundFlash: {},
    backgroundParticle: {},
    backgroundShimmer: {},
  },
};

export default BackgroundPanels;

import {
  CHANGE_TAB,
} from '../actions/tabs';

export const initialState = {
  activeTab: 'color-palette',
};

export default (state = initialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case CHANGE_TAB:
      return {
        ...state,
        activeTab: payload,
      };
    default:
      return state;
  }
};

import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Grid,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import Select from '../../../../common/Select';
import CollapsiblePanel from '../../../../common/CollapsiblePanel';
import Slider from '../../../../common/Slider';


const musicStaffOptions = [
  { key: 'none', label: 'None' },
  { key: 'treble', label: 'Treble' },
  { key: 'alto', label: 'Alto' },
  { key: 'bass', label: 'Bass' },
  { key: 'piano', label: 'Piano' },
];

function OtherPanels({
  activePanels,
  values: {
    hideThePast = {},
    musicStaff = {},
  },
  onChange,
  onPanelCollapse,
  disabled,
  onTriggerClick,
}) {
  return (
    <Box>
      <CollapsiblePanel
        onClick={() => onTriggerClick('hideThePast')}
        title="Hide the past"
        isExpanded={activePanels.includes('hideThePast')}
        handleChange={() => onPanelCollapse('hideThePast')}
        disabled={disabled}
      >
        <Grid container>
          <Grid item xs={12}>
            <FormControlLabel
              control={(
                <Checkbox
                  onChange={(event, hide) => onChange('hideThePast', { hide })}
                  value={hideThePast.hide}
                  color="primary"
                />
              )}
              label="Hide"
            />
          </Grid>
          <Grid item xs={12}>
            <Slider
              title="Transition"
              labelLeft="Slow"
              labelRight="Fast"
              value={hideThePast.transitionDuration}
              onChange={(transitionDuration) => onChange('hideThePast', { transitionDuration })}
              valueLabelDisplay="off"
            />
          </Grid>
        </Grid>
      </CollapsiblePanel>
      <CollapsiblePanel
        onClick={() => onTriggerClick('musicStaff')}
        title="Music staff"
        isExpanded={activePanels.includes('musicStaff')}
        handleChange={() => onPanelCollapse('musicStaff')}
        disabled={disabled}
      >
        <Grid container>
          <Grid item xs={12}>
            <Select
              value={musicStaff.type}
              label="Type"
              onChange={(type) => onChange('musicStaff', { type })}
              options={musicStaffOptions}
            />
          </Grid>
          <Grid item xs={12}>
            <Slider
              title="Transition"
              labelLeft="Fast"
              labelRight="Slow"
              value={musicStaff.transitionDuration}
              onChange={(transitionDuration) => onChange('musicStaff', { transitionDuration })}
              valueLabelDisplay="off"
            />
          </Grid>
        </Grid>
      </CollapsiblePanel>
    </Box>
  );
}

OtherPanels.propTypes = {
  values: PropTypes.shape({
    hideThePast: PropTypes.shape({
      hide: PropTypes.bool,
      transitionDuration: PropTypes.number,
    }),
    musicStaff: PropTypes.shape({
      type: PropTypes.string,
      transitionDuration: PropTypes.number,
    }),
  }),
  activePanels: PropTypes.arrayOf(PropTypes.string).isRequired,
  onPanelCollapse: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onTriggerClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

OtherPanels.defaultProps = {
  values: {
    hideThePast: {
      hide: false,
      transitionDuration: 5,
    },
    musicStaff: {
      type: 'none',
      transitionDuration: 5,
    },
  },
};

export default OtherPanels;

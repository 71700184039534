
import omit from 'lodash.omit';

import {
  UPDATE_ANIMATION,
  CHANGE_ANIMATION,
  REMOVE_ANIMATION,
} from '../actions/animations';

import {
  ADD_ANIMATION,
  REMOVE_ACTIVE_ANIMATION,
  SET_ANIMATIONS_DATA_STRUCTURE,
} from '../actions/timeline';

const defaultAnimation = {
  afterPlay: null,
  beforePlay: null,
  color: 0,
  onPlay: null,
  shape: 'bar',
  startTime: 0,
  transitionDuration: 5,
};

const initialState = {
  violin1: {
    name: 'Violin I',
    items: [],
  },
  violin2: {
    name: 'Violin II',
    items: [],
  },
  viola: {
    name: 'Viola',
    items: [],
  },
  cello: {
    name: 'Cello',
    items: [],
  },
};

const animationsReducer = (state = initialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case SET_ANIMATIONS_DATA_STRUCTURE: {
      return payload.tracks.reduce((tracks, track) => ({
        ...tracks,
        [track.id]: {
          ...track,
          items: [],
        },
      }), {});
    }
    case CHANGE_ANIMATION: {
      const {
        data,
        selectedAnimation,
      } = payload;
      const [section, animation] = Object.entries(state).find(([key, { items }]) => items
        .find(({ id }) => id === selectedAnimation));
      const index = animation.items.findIndex(({ id }) => id === selectedAnimation);

      return {
        ...state,
        [section]: {
          ...state[section],
          items: [
            ...state[section].items.slice(0, index),
            {
              ...state[section].items[index],
              ...data,
            },
            ...state[section].items.slice(index + 1, state.length),
          ],
        },
      };
    }
    case ADD_ANIMATION: {
      const {
        key,
        data,
      } = payload;
      const sectionItems = state[key].items;
      const previousAnimation = sectionItems.length > 0
        ? sectionItems[sectionItems.length - 1]
        : defaultAnimation;

      return {
        ...state,
        [key]: {
          ...state[key],
          items: [
            ...state[key].items,
            {
              ...previousAnimation,
              ...data,
            },
          ],
        },
      };
    }
    case UPDATE_ANIMATION:
      return {
        ...state,
        ...payload,
      };
    case REMOVE_ACTIVE_ANIMATION: {
      return omit(state, payload);
    }
    case REMOVE_ANIMATION: {
      const [section] = Object.entries(state)
        .find(([key, { items }]) => items.find(({ id }) => id === payload));

      return {
        ...state,
        [section]: {
          ...state[section],
          items: [
            ...state[section].items.filter(({ id }) => id !== payload),
          ],
        },
      };
    }
    default:
      return state;
  }
};


export default animationsReducer;

import timelineSelectors from './timeline';

// eslint-disable-next-line import/prefer-default-export
export const cameraSelectors = {
  getCameras: (state) => state.camera.present,
  getSelectedCamera: (state) => {
    const selectedCameraId = timelineSelectors.getSelectedCamera(state);
    return Number.isInteger(selectedCameraId)
      ? state.camera.present.camera.items[selectedCameraId]
      : undefined;
  },
};

import { updateAnimation } from './animations';
import { updateTriggers } from './triggers';
import { updateTimeline } from './timeline';
import { updateSettings } from './settings';
import { updateMoments } from './moments';

const PREFIX = '[REVISIONS]';

export const SAVE_REVISION = `${PREFIX} SAVE REVISION`;
export const saveRevision = (payload) => ({
  type: SAVE_REVISION,
  payload,
});

export const restoreRevision = ({
  animations,
  triggers,
  timeline,
  settings,
  moments,
}) => (dispatch) => {
  dispatch(updateAnimation(animations));
  dispatch(updateTriggers(triggers));
  dispatch(updateTimeline(timeline));
  dispatch(updateSettings(settings));
  dispatch(updateMoments(moments));
};

import { createSelector } from 'reselect';
import timelineSelectors from './timeline';


function getCurrentTrigger(state) {
  const selectedId = timelineSelectors.getSelectedTriggerId(state);
  const currentTrigger = Object.values(state.triggers.present)
    .map(({ items }) => items)
    .flat()
    .find(({ id }) => id === selectedId);
  return currentTrigger;
}

const getSelectedTrigger = createSelector([
  ({ triggers }) => triggers.present,
  ({ timeline }) => timeline.present.selectedTrigger,
], (triggers, selectedTrigger) => {
  const {
    id,
    section,
  } = selectedTrigger;

  return triggers[section] ? triggers[section].items[id] : undefined;
});

function getSelectedTriggerDuration(state) {
  const selectedTrigger = getSelectedTrigger(state);
  if (!selectedTrigger) {
    return null;
  }
  return Object.values(selectedTrigger)
    .reduce((longestDuration, trigger) => (trigger.transitionDuration > longestDuration
      ? trigger.transitionDuration
      : longestDuration), null);
}

const triggersSelectors = {
  getSelectedTrigger,
  getTriggers: (state) => state.triggers.present,
  getMoments: (state) => state.moments.present,
  getCurrentTrigger: (state) => getCurrentTrigger(state),
  getEnabledTriggers: (state) => {
    const { enabledTriggers } = getCurrentTrigger(state) || {};
    return enabledTriggers || [];
  },
  getSelectedTriggerDuration,
  getNotActivatedTriggers: createSelector([
    ({ triggers }) => Object.values(triggers.present).map(({ items }) => items).flat(),
    (state, selectedTrigger) => selectedTrigger,
  ], (triggers, selectedTrigger) => triggers
    .filter((trigger) => trigger.id !== selectedTrigger || trigger.enabledTriggers.length < 1)),
};

export default triggersSelectors;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  Box,
  Divider,
  Button,
  Typography,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import Slider from '../../../common/Slider';
import { getCurrentTime } from '../../../common/Utils';
import {
  changeCamera,
  removeCamera,
} from '../../../store/actions/camera';
import { cameraSelectors } from '../../../store/selectors/camera';


const styles = ({ palette }) => ({
  wrapper: {
    height: '100%',
  },
  removeButton: {
    background: palette.error.main,
    color: palette.error.contrastText,
    '&:hover': {
      background: palette.error.dark,
    },
  },
});

// eslint-disable-next-line react/prefer-stateless-function
class Camera extends Component {
  render() {
    const {
      classes,
      changeCamera,
      removeCamera,
      selectedCamera,
    } = this.props;
    const currentTime = getCurrentTime(selectedCamera.startTime);
    const isDisabled = !Number.isInteger(selectedCamera.id);

    return (
      <Box display="flex" flexDirection="column" className={classes.wrapper}>
        {isDisabled ? (
          <Box p={2}>
            <Typography color="primary">You don&apos;t have any Camera yet, click on the timeline to add one.</Typography>
          </Box>
        ) : (
          <>
            <Box flexGrow="1">
              <Box display="flex" flexDirection="column">
                <Typography variant="h6" color="primary">
                  Camera angle {currentTime && (`at ${currentTime}`)}
                </Typography>
              </Box>
              <Box paddingTop={2} paddingBottom={2}>
                <Divider variant="fullWidth" />
              </Box>
              <Box display="flex" flexDirection="column">
                <Typography variant="h6">
                  Camera target
                </Typography>
                <Box
                  display="flex"
                  flexDirection="column"
                  paddingLeft={2}
                  paddingRight={2}
                >
                  <Box>
                    <Slider
                      min={-100}
                      max={100}
                      disabled={isDisabled}
                      title="X:"
                      labelLeft={`${selectedCamera.target ? selectedCamera.target.x : 0}`}
                      value={selectedCamera.target ? selectedCamera.target.x : 0}
                      onChange={(x) => changeCamera({
                        id: selectedCamera.id,
                        data: {
                          target: {
                            ...selectedCamera.target,
                            x,
                          },
                        },
                      })}
                    />
                  </Box>
                  <Box>
                    <Slider
                      min={-100}
                      max={100}
                      disabled={isDisabled}
                      title="Y:"
                      labelLeft={`${selectedCamera.target ? selectedCamera.target.y : 0}`}
                      value={selectedCamera.target ? selectedCamera.target.y : 0}
                      onChange={(y) => changeCamera({
                        id: selectedCamera.id,
                        data: {
                          target: {
                            ...selectedCamera.target,
                            y,
                          },
                        },
                      })}
                    />
                  </Box>
                  <Box>
                    <Slider
                      min={-100}
                      max={100}
                      disabled={isDisabled}
                      title="Z:"
                      labelLeft={`${selectedCamera.target ? selectedCamera.target.z : 0}`}
                      value={selectedCamera.target ? selectedCamera.target.z : 0}
                      onChange={(z) => changeCamera({
                        id: selectedCamera.id,
                        data: {
                          target: {
                            ...selectedCamera.target,
                            z,
                          },
                        },
                      })}
                    />
                  </Box>
                </Box>
                <Typography>
                  Hint: You can also drag the animation to change where the camera is looking.
                </Typography>
              </Box>
              <Box paddingTop={2} paddingBottom={2}>
                <Divider variant="fullWidth" />
              </Box>
              <Box display="flex" flexDirection="column">
                <Typography variant="h6">
                  Camera position
                </Typography>
                <Box
                  display="flex"
                  flexDirection="column"
                  paddingLeft={2}
                  paddingRight={2}
                >
                  <Box>
                    <Slider
                      min={-100}
                      max={100}
                      disabled={isDisabled}
                      title="X:"
                      labelLeft={`${selectedCamera.position ? selectedCamera.position.x : 0}`}
                      value={selectedCamera.position ? selectedCamera.position.x : 0}
                      onChange={(x) => changeCamera({
                        id: selectedCamera.id,
                        data: {
                          position: {
                            ...selectedCamera.position,
                            x,
                          },
                        },
                      })}
                    />
                  </Box>
                  <Box>
                    <Slider
                      min={-100}
                      max={100}
                      disabled={isDisabled}
                      title="Y:"
                      labelLeft={`${selectedCamera.position ? selectedCamera.position.y : 0}`}
                      value={selectedCamera.position ? selectedCamera.position.y : 0}
                      onChange={(y) => changeCamera({
                        id: selectedCamera.id,
                        data: {
                          position: {
                            ...selectedCamera.position,
                            y,
                          },
                        },
                      })}
                    />
                  </Box>
                  <Box>
                    <Slider
                      min={-100}
                      max={100}
                      disabled={isDisabled}
                      title="Z:"
                      labelLeft={`${selectedCamera.position ? selectedCamera.position.z : 0}`}
                      value={selectedCamera.position ? selectedCamera.position.z : 0}
                      onChange={(z) => changeCamera({
                        id: selectedCamera.id,
                        data: {
                          position: {
                            ...selectedCamera.position,
                            z,
                          },
                        },
                      })}
                    />
                  </Box>
                  <Box>
                    <Slider
                      min={-100}
                      max={100}
                      disabled={isDisabled}
                      title="Zoom:"
                      labelLeft={`${selectedCamera.position ? selectedCamera.position.zoom : 0}`}
                      value={selectedCamera.position ? selectedCamera.position.zoom : 0}
                      onChange={(zoom) => changeCamera({
                        id: selectedCamera.id,
                        data: {
                          position: {
                            ...selectedCamera.position,
                            zoom,
                          },
                        },
                      })}
                    />
                  </Box>
                  <Box>
                    <Slider
                      min={-100}
                      max={100}
                      disabled={isDisabled}
                      title="Roll:"
                      labelLeft={`${selectedCamera.position ? selectedCamera.position.roll : 0}`}
                      value={selectedCamera.position ? selectedCamera.position.roll : 0}
                      onChange={(roll) => changeCamera({
                        id: selectedCamera.id,
                        data: {
                          position: {
                            ...selectedCamera.position,
                            roll,
                          },
                        },
                      })}
                    />
                  </Box>
                </Box>
                <Typography>
                  Hint: Drag with the right mouse button to move the camera (pan). Use the mouse scroll to zoom in/out.
                </Typography>
              </Box>
            </Box>
            <Box display="flex" flexDirection="column">
              <Button
                className={classes.removeButton}
                variant="contained"
                onClick={() => removeCamera(selectedCamera)}
                disabled={isDisabled}
              >
                <DeleteIcon />
                Remove camera
              </Button>
            </Box>
          </>
        )}
      </Box>
    );
  }
}

Camera.propTypes = {
  changeCamera: PropTypes.func.isRequired,
  selectedCamera: PropTypes.shape({
    id: PropTypes.number,
    startTime: PropTypes.number,
    target: PropTypes.object,
    position: PropTypes.object,
  }),
  classes: PropTypes.shape({
    removeButton: PropTypes.string,
  }).isRequired,
};

Camera.defaultProps = {
  selectedCamera: {
    id: null,
    target: null,
    position: null,
  },
};

const mapStateToProps = (state) => ({
  selectedCamera: cameraSelectors.getSelectedCamera(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  changeCamera,
  removeCamera,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Camera));

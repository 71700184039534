import { tabsSelectors } from './tabs';

const getSelectedTimelineItem = (timelineItems, selectedItem) => {
  return Object.values(timelineItems)
    .map(({ items }) => items)
    .flat()
    .find(({ id }) => id === selectedItem);
};

const timelineSelectors = {
  getTimelineItems: (state) => {
    const activeTab = tabsSelectors.getActiveTab(state);

    switch (activeTab) {
      case 'triggers':
        return state.triggers.present;
      case 'moments':
        return state.moments.present;
      case 'camera':
        return state.camera.present;
      default:
        return state.timeline.present.animations;
    }
  },
  getSelectedTimelineItem: (state) => {
    const activeTab = tabsSelectors.getActiveTab(state);

    switch (activeTab) {
      case 'triggers': {
        const { selectedTrigger } = state.timeline.present;
        const selectedTriggerId = selectedTrigger;
        return getSelectedTimelineItem(state.triggers.present, selectedTriggerId);
      }
      case 'moments': {
        const { selectedMoment } = state.timeline.present;
        return getSelectedTimelineItem(state.moments.present, selectedMoment);
      }
      case 'camera': {
        const { selectedCamera } = state.timeline.present;
        return getSelectedTimelineItem(state.camera.present, selectedCamera);
      }
      default: {
        const { selectedAnimation } = state.timeline.present;
        return getSelectedTimelineItem(state.animations.present, selectedAnimation);
      }
    }
  },
  getSelectedTriggerId: (state) => state.timeline.present.selectedTrigger.id,
  getSelectedTriggerSection: (state) => state.timeline.present.selectedTrigger.section,
  getSelectedAnimation: (state) => state.timeline.present.selectedAnimation,
  getSelectedMoment: (state) => state.timeline.present.selectedMoment,
  getSelectedCamera: (state) => state.timeline.present.selectedCamera,
};

export default timelineSelectors;

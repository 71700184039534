import React from 'react';
import PropTypes from 'prop-types';
import { CustomPicker } from 'react-color';
import {
  Hue,
  Saturation,
  ChromePointerCircle,
} from 'react-color/lib/components/common';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles(({ palette }) => ({
  swatch: ({ color }) => ({
    width: 30,
    height: 30,
    backgroundColor: color,
    borderRadius: 5,
    position: 'relative',
    overflow: 'hidden',
  }),
  slider: {
    width: '100%',
    height: 30,
    borderRadius: 5,
    overflow: 'hidden',
    position: 'relative',
    '.hue-horizontal > div': {
      height: 100,
    },
  },
  slidersWrapper: {
    height: 30,
    width: '100%',
    justifyContent: 'space-between',
    marginRight: 8,
    cursor: 'grab',
    '&:active': {
      cursor: 'grabbing',
    },
  },
  sliderPointer: {
    width: 5,
    height: 30,
    border: `2px solid ${palette.grey[900]}`,
    borderRadius: 3,
    backgroundColor: palette.common.white,
  },
  saturation: {
    position: 'relative',
    width: '100%',
    height: 100,
  },
  controlsWrapper: {
    width: '100%',
    paddingTop: 8,
  },
}));

function SwatchesPicker({
  hsv,
  hsl,
  hex,
  onChange,
}) {
  const classes = useStyles({ color: hex });
  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Box className={classes.saturation}>
        <Saturation
          hsl={hsl}
          hsv={hsv}
          pointer={ChromePointerCircle}
          onChange={onChange}
        />
      </Box>
      <Box display="flex" className={classes.controlsWrapper}>
        <Box
          display="flex"
          flexGrow={1}
          flexDirection="column"
          className={classes.slidersWrapper}
        >
          <Box className={classes.slider}>
            <Hue
              hsl={hsl}
              onChange={onChange}
              pointer={() => <div className={classes.sliderPointer} />}
            />
          </Box>
        </Box>
        <Box display="flex">
          <Box className={classes.swatch} />
        </Box>
      </Box>
    </Box>
  );
}

SwatchesPicker.propTypes = {
  hsv: PropTypes.shape({}),
  hsl: PropTypes.shape({}),
  hex: PropTypes.string,
  onChange: PropTypes.func,
};

SwatchesPicker.defaultProps = {
  hsv: null,
  hsl: null,
  hex: null,
  onChange: null,
};

export default CustomPicker(SwatchesPicker);

import React, {
  Component,
} from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@material-ui/styles';
import {
  Box,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import DetailsPanel from './containers/DetailsPanel';
import Timeline from './containers/Timeline';
import NavigationMenu from './containers/NavigationMenu';
import Player from './containers/Player';
import Tabs from './containers/Tabs';
import SnapshotsPanel from './containers/SnapshotsPanel';
import configureStore from './store/store';
import theme from './theme';
import MusaicContext from './contexts/musaic';
import Playback from './services/Playback';

const styles = {
  container: {
    height: 'calc(100% - 65px)',
  },
  detailsPanelContainer: {
    overflow: 'hidden',
  },
};

const store = configureStore();

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isWizardEnabled: false, // Enable Animation Wizard
      isScriptLoaded: false,
      isSnapshotsPanelOpened: false,
    };

    this.handleScriptOnLoad = this.handleScriptOnLoad.bind(this);
    this.handleSnapshotsPanelToggle = this.handleSnapshotsPanelToggle.bind(this);
    this.handleSnapshotsPanelToggle = this.handleSnapshotsPanelToggle.bind(this);
    this.handleWizardDone = this.handleWizardDone.bind(this);
  }

  handleScriptOnLoad() {
    const {
      isScriptLoaded,
    } = this.state;

    Playback.start(25);

    if (!isScriptLoaded) {
      this.setState({ isScriptLoaded: true });
    }
  }

  handleSnapshotsPanelToggle() {
    const {
      isSnapshotsPanelOpened,
    } = this.state;

    this.setState({
      isSnapshotsPanelOpened: !isSnapshotsPanelOpened,
    });
  }

  handleSnapshotsPanelClose() {
    this.setState({
      isSnapshotsPanelOpened: false,
    });
  }

  handleWizardDone() {
    this.setState({
      isWizardEnabled: false,
    });
  }

  render() {
    const {
      isSnapshotsPanelOpened,
      isScriptLoaded,
      isWizardEnabled,
    } = this.state;

    const {
      classes,
    } = this.props;

    return (
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <MusaicContext>
            <NavigationMenu
              isWizardEnabled={isWizardEnabled}
              toggleSnapshotsPanel={this.handleSnapshotsPanelToggle}
            />
            <Box
              display="flex"
              flexDirection="row"
              alignItems="stretch"
              className={classes.container}
            >
              <Box
                display="flex"
                flexGrow={1}
                flexDirection="column"
              >
                <Box display="flex" flexGrow={1}>
                  {!isWizardEnabled && (
                    <Box display="flex">
                      <Tabs isScriptLoaded={isScriptLoaded} />
                    </Box>
                  )}
                  <Box display="flex" flexGrow={1}>
                    <Player
                      onLoad={this.handleScriptOnLoad}
                      isScriptLoaded={isScriptLoaded}
                    />
                  </Box>
                </Box>
                {!isWizardEnabled && (
                  <Timeline
                    isScriptLoaded={isScriptLoaded}
                  />
                )}
              </Box>
              <Box display="flex" flexGrow={0} className={classes.detailsPanelContainer}>
                <DetailsPanel
                  isWizardEnabled={isWizardEnabled}
                  onWizardDone={this.handleWizardDone}
                />
              </Box>
              <SnapshotsPanel
                isOpened={isSnapshotsPanelOpened}
                onClose={this.handleSnapshotsPanelClose}
              />
            </Box>
          </MusaicContext>
        </ThemeProvider>
      </Provider>
    );
  }
}

App.propTypes = {
  classes: PropTypes.shape({
    container: PropTypes.string,
    detailsPanelContainer: PropTypes.string,
  }).isRequired,
};

export default withStyles(styles)(App);

import React, {
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { Rnd } from 'react-rnd';
import {
  makeStyles,
} from '@material-ui/core/styles';
import {
  Box,
  Button,
  Divider,
  Modal,
  Typography,
} from '@material-ui/core';
import ColorsAndShapes from '../../Animations/components/ColorsAndShapes';

const useStyles = makeStyles(() => ({
  modalContainer: {
    position: 'absolute !important',
  },
  modal: {
    background: '#FFF',
    width: 500,
    borderRadius: 12,
    padding: 12,
  },
}));

function ColorsAndShapesModal({
  isOpened,
  noteColors,
  onDoneClick,
}) {
  const classes = useStyles();
  const [values, handleChange] = useState({
    color: 0,
    shape: 'bar',
  });

  return (
    <Modal
      open={isOpened}
      hideBackdrop
      className={classes.modalContainer}
      container={document.querySelector('.Player')}
    >
      <Rnd
        enableResizing={false}
        default={{
          x: 250,
          y: 50,
        }}
      >
        <Box className={classes.modal}>
          <Typography variant="h6" color="primary">
            Animation wizard
          </Typography>
          <Box paddingTop={2} paddingBottom={2}>
            <Divider variant="fullWidth" />
          </Box>
          <Box>
            <ColorsAndShapes
              color={values.color}
              noteColors={noteColors}
              onChange={(data) => handleChange({
                ...values,
                ...data,
              })}
              shape={values.shape}
            />
          </Box>
          <Box paddingTop={2} paddingBottom={2}>
            <Divider variant="fullWidth" />
          </Box>
          <Box display="flex" justifyContent="space-between">
            <Button
              variant="contained"
            >
              More colors
            </Button>

            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                handleChange({
                  color: 0,
                  shape: 'bar',
                });
                onDoneClick(values);
              }}
            >
              Done
            </Button>
          </Box>
        </Box>
      </Rnd>
    </Modal>
  );
}

ColorsAndShapesModal.propTypes = {
  noteColors: PropTypes.arrayOf(
    PropTypes.string,
  ).isRequired,
  onDoneClick: PropTypes.func.isRequired,
  isOpened: PropTypes.bool.isRequired,
};

export default ColorsAndShapesModal;

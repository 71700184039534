import React, {
  useContext,
} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ActionCreators } from 'redux-undo';
import { bindActionCreators } from 'redux';
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
} from '@material-ui/core';
import {
  Menu as MenuIcon,
  Bookmark as BookmarkIcon,
  Undo as UndoIcon,
  Redo as RedoIcon,
  History as HistoryIcon,
  Fullscreen as FullscreenIcon,
} from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { historySelectors } from '../store/selectors/history';
import { MusaicDataContext } from '../contexts/MusaicDataContext';


const useStyles = makeStyles(() => ({
  title: {
    flexGrow: 1,
  },
}));

function NavigationMenu({
  pastHistory,
  futureHistory,
  undoHistory,
  redoHistory,
  toggleSnapshotsPanel,
  isWizardEnabled,
}) {
  const classes = useStyles();
  const musaicContext = useContext(MusaicDataContext);

  return (
    <AppBar position="static">
      <Toolbar>
        <IconButton
          edge="start"
          color="inherit"
        >
          <MenuIcon />
        </IconButton>
        <Typography className={classes.title} variant="h6" color="inherit">
          {musaicContext.musaic && musaicContext.musaic.name}
        </Typography>
        <div>
          <IconButton
            edge="start"
            color="inherit"
          >
            <BookmarkIcon />
          </IconButton>
          {!isWizardEnabled && (
            <>
              <IconButton
                edge="start"
                color="inherit"
                disabled={!pastHistory.length}
                onClick={undoHistory}
              >
                <UndoIcon />
              </IconButton>
              <IconButton
                edge="start"
                color="inherit"
                disabled={!futureHistory.length}
                onClick={redoHistory}
              >
                <RedoIcon />
              </IconButton>
            </>
          )}
          <IconButton
            edge="start"
            color="inherit"
            onClick={toggleSnapshotsPanel}
          >
            <HistoryIcon />
          </IconButton>
          <IconButton
            edge="start"
            color="inherit"
          >
            <FullscreenIcon />
          </IconButton>
        </div>
      </Toolbar>
    </AppBar>
  );
}

NavigationMenu.propTypes = {
  isWizardEnabled: PropTypes.bool.isRequired,
  pastHistory: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
  ])).isRequired,
  futureHistory: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
  ])).isRequired,
  undoHistory: PropTypes.func.isRequired,
  redoHistory: PropTypes.func.isRequired,
  toggleSnapshotsPanel: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
  undoHistory: ActionCreators.undo,
  redoHistory: ActionCreators.redo,
}, dispatch);

const mapStateToProps = (state) => ({
  pastHistory: historySelectors.getPastHistory(state),
  futureHistory: historySelectors.getFutureHistory(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(NavigationMenu);
